import  $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,GetImgCertUser,RespBkMthdMapp} from '../function_lib/common_lib';
import {CallCMSMapMethod} from '../function_lib/common_cms';
import Front_Header from './front_header';

import Product_Listing from '../sample_comp/product_listing';

import Top_Banner from '../sample_comp/top_banner';
import Top_Specified from './top_specified';
import Home_Product from './home_product';
import Product_Slider from '../sample_comp/product_slider';
import Features_Slider  from '../sample_comp/features_slider';
import Feature_Web_List from '../sample_comp/feature_web_list';
import Article_Listing from '../sample_comp/article_listing';
import Home_Banner from '../sample_comp/home_banner';
import Article_Blogs from '../sample_comp/article_blogs';
import Left_Img_Txt_Desc from '../sample_comp/left_img_txt_desc';
import Single_Img_Msg from '../sample_comp/single_img_msg';
import Txt_Description from '../sample_comp/txt_description';
import ImageGallery from '../sample_comp/image_gallery';
import Video_Slider from '../sample_comp/video_slider';
import Collapse_Pan from '../sample_comp/collapse_pan';
import Feature_Card from '../sample_comp/feature_card';
import Testimonials from '../sample_comp/testimonials';
import Blogs from '../sample_comp/blogs';
import Time_Line from '../sample_comp/time_line';

import Single_Product from '../sample_comp/single_product';
import Footer from './footer';
import QueryString from 'query-string';
import AOS from 'aos';
import './product_landing.css';
import { main } from '@popperjs/core';

let clsObject;
let rf="0";
let queries;
let proData;
let rateData;
let renderData;

let loc="";
let _topTitle="";
let listObj="";

/* Only Use for ProductLanding */
let lp_Usage;
let lp_Description;
let lp_FAQ;
let lp_Ingred;
class Product_Landing extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };
setCartBk(refId){
     //alert(refId);
     this.setState(state =>({viewData:refId}));
     this.forceUpdate();
     $("#offcanvas-cart").addClass("offcanvas-open");
   }
  constructor(props){
    super(props);
    this.state={viewData:false,isPgData:false};
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    loc=props.location["pathname"].substr(1);
    //alert(listObj.access);
    // alert(loc);
    // this.state={viewData:"0"};
    if(props !=null){
      queries = QueryString.parse(this.props.location.search);
            if(queries.rf!=null){
              rf=queries.rf;
            }
    }
  }
  getProductPgData(){
    let pgMap=[];
    pgMap.push({name:"curl_type",value:"getPgData"});
    pgMap.push({name:"res_func",value:"callBackpgData"});
    pgMap.push({name:"loc",value:loc});
   // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
    //mapCat.push({name:"emp_id",value:userObject[0].id});
    pgMap["res_func"]="callBackpgData";
    pgMap["curl_type"]="getPgData";
    CallCMSMapMethod(clsObject,pgMap);
  }
  callBackpgData(data,msg){
     //alert("testing of2 "+data);
    //$("#pgComp").html(data);
    
     listObj= JSON.parse("{\"access\":"+data+"}");
    
this.setState({isPgData:true,viewData:true},()=>{
  RespBkMthdMapp(this,"_addToCart","setCartBk");
  
});
this.forceUpdateHandler();
    
    //alert("successfully parse");
    var mainHt="";
    if(loc==="home"){
       // mainHt+=Video_Banner_Comp();
    }

    var injextI=0;
    var injCondition=false;
    var ifImgGallery=false;
    
    var strTopBanner="";
    for(var _dlist=0;_dlist<50;_dlist++){
     
        if(listObj.access[_dlist]){
            
            if(listObj.access[_dlist].top_banner){
               // alert("I am in conditio");
               injCondition=true;
               _topTitle=listObj.access[_dlist].top_banner[0]["top_title"];
               strTopBanner=Top_Banner(listObj.access[_dlist].top_banner);
            }
            else if(listObj.access[_dlist].home_banner){
               // alert("i am in cond..");
               injCondition=true;
               //alert(JSON.stringify(listObj.access[_dlist].home_banner[0]));
               _topTitle=listObj.access[_dlist].home_banner[0]["top_title"];
                mainHt+=Home_Banner(listObj.access[_dlist].home_banner);
            }else if(listObj.access[_dlist].dual_text_card){
                injCondition=true;
                //var dbs=render(){return(new Dual_Banner(listObj.access[_dlist].dual_text_card)};
               // mainHt+=Dual_Banner(listObj.access[_dlist].dual_text_card);
            }else if(listObj.access[_dlist].single_img_text){
                injCondition=true;
                _topTitle=listObj.access[_dlist].single_img_text[0]["top_title"];
                mainHt+=Single_Img_Msg(listObj.access[_dlist].single_img_text);
            }else if(listObj.access[_dlist].img_text_desc_img_left){
                injCondition=true;
                _topTitle=listObj.access[_dlist].img_text_desc_img_left[0]["top_title"];
                mainHt+=Left_Img_Txt_Desc(listObj.access[_dlist].img_text_desc_img_left);
                if(listObj.access[_dlist].img_text_desc_img_left[0]["g_title"].trim().toUpperCase()==="USAGE"){
                  lp_Usage=mainHt;
                  mainHt="";
                }
            }else if(listObj.access[_dlist].text_description){
                injCondition=true;
               
                mainHt+=Txt_Description(listObj.access[_dlist].text_description);
                if(listObj.access[_dlist].text_description[0]["g_title"].trim().toUpperCase()==="USAGE"){
                  // lp_Usage=mainHt;
                }else if(listObj.access[_dlist].text_description[0]["g_title"].trim().toUpperCase()==="DESCRIPTION"){
                  lp_Description=mainHt;
                  mainHt="";
                }
            }else if(listObj.access[_dlist].list_slider){
                injCondition=true;
               // alert(JSON.stringify(listObj.access[_dlist].list_slider[0]["g_title"].trim()));
                mainHt+=Article_Blogs(listObj.access[_dlist].list_slider);
                if(listObj.access[_dlist].list_slider[0]["g_title"].trim().toUpperCase()==="INGREDIENT"){
                 //alert(JSON.stringify(listObj.access[_dlist].list_slider));
                  lp_Ingred=Article_Blogs(listObj.access[_dlist].list_slider,"1");
                  mainHt="";
                }
                
            }else if(listObj.access[_dlist].image_gallery){
                injCondition=true;
                ifImgGallery=true;
                mainHt+=ImageGallery(listObj.access[_dlist].image_gallery);
            }else if(listObj.access[_dlist].video_slider){
                //alert("I am in testing");
                injCondition=true;
                mainHt+=Video_Slider(listObj.access[_dlist].video_slider);
            }
            else if(listObj.access[_dlist].blogs){
                
                injCondition=true;
                mainHt+=Blogs(listObj.access[_dlist].blogs);
            }else if(listObj.access[_dlist].feature_web_list){
                injCondition=true;
                //alert("I am in feature "+listObj.access[_dlist].feature_web_list);
                mainHt+=Feature_Card(listObj.access[_dlist].feature_web_list);
            }else if(listObj.access[_dlist].article_listing){
                injCondition=true;
                // alert("I am in feature "+listObj.access[_dlist].article_listing);
                mainHt+=Article_Listing(listObj.access[_dlist].article_listing);
            }else if(listObj.access[_dlist].collapse_pan){
                injCondition=true;
                mainHt+=Collapse_Pan(listObj.access[_dlist].collapse_pan,"1");
                lp_FAQ=mainHt;
                mainHt="";
            }else if(listObj.access[_dlist].time_line){
                injCondition=true;
                mainHt+=Time_Line(listObj.access[_dlist].time_line);
            }else if(listObj.access[_dlist].testimonials){
                injCondition=true;
                mainHt+=Testimonials(listObj.access[_dlist].testimonials);
            }else if(listObj.access[_dlist].top_info_slider){
              injCondition=true;
              //alert("i am in topindo");
              $("#top_msg_bar").html(listObj.access[_dlist].top_info_slider[0]["col2"]);
            }
            if(injCondition){
                injextI++;
            }
            /* Only to assig FAQ,Descri etc */
            if(injextI==1){
              //lp_Usage=mainHt;
              mainHt="";
            }else if(injextI==2){
              //lp_Description=mainHt;
              mainHt="";
            }else if(injextI==3){
              
             // mainHt="";
            }else if(injextI==4){
             // mainHt="";
            }
            if(injextI==1){
                //alert("check in condi == "+loc)
                if(loc==="home"){
                    mainHt+=Features_Slider();
                    //alert(products);
                   // mainHt+=products;
                }
           }
           
        }

      
        //$("#pgComp").innerHtml=mainHt;
    }
    
    $("#proIngredient").html(lp_Ingred);
    $("#pro_usage").html(lp_Usage);
    $("#pro_description").html(lp_Description);
    $("#pro_faq").html(lp_FAQ);
    $("#pgComp").append(mainHt);


    const script = document.createElement("script");
    script.src = "/main.js";
    script.async = true;
    //script.onload = () => clsObject.scriptLoaded();
  
    document.body.appendChild(script);
      //this.forceUpdateHandler();
    if(ifImgGallery){
        $('.gallery-item').magnificPopup({
            type: 'image',
            gallery: {
                enabled: true
            }
        });
    }
  }
    componentDidMount(){
      //alert(rf);
      clsObject=this;
      var mapCat=[];
      mapCat.push({name:"curl_type",value:"getProductLanding"});
      mapCat.push({name:"res_func",value:"callBackProList"});
      mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
      mapCat.push({name:"p1",value:"0"});
      mapCat.push({name:"p2",value:"0"});
      if(rf!=="0"){
        mapCat.push({name:"rf",value:rf});
      }
     // mapCat.push({name:"emp_id",value:userObject[0].id});
      mapCat["res_func"]="callBackProList";
      mapCat["curl_type"]="getProductLanding";
      CallMapMethod(clsObject,mapCat);
        //$("#pgComp").html(mainHt);
        AOS.init();
        AOS.init({
          once: true
        });
        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
      
        document.body.appendChild(script);
       
    }
    callBackProList(data,msg){
      //alert(data);
      //return;
      if(data!=="" && data!=="0"){
        let mainData=JSON.parse(data);
        proData=mainData.product;
        rateData=mainData.ratings;

        this.getProductPgData();

        // this.setState({viewData:true},()=>{
        //   RespBkMthdMapp(this,"_addToCart","setCartBk");
        // });
      }
     
  }
    render(){
        return(<div>
            <Front_Header loc={loc} checkFdata={(typeof listObj.access!=='undefined')?listObj.access[1]:''} />
           
            <div id="pgComp">
               {(this.state.viewData) ?
                <Single_Product proData={proData} ratings={rateData} /> : ''}
          </div>
    <Footer/>
       </div>)
    }
}
export default Product_Landing;