import $ from 'jquery';
import React,{useEffect} from 'react';
import { Helmet } from 'react-helmet';
import UserToken from '../function_lib/userToken';
import axios from 'axios';
import {CallMapMethod,RespBkMthdMapp,AjaxCallMethod,GetImgCertUser,CallMapMethod_CBack,appGetUrlPath} from '../function_lib/common_lib';
import {getRoleDetails,CallCMSMapMethod} from '../function_lib/common_cms';
import Product_Listing from '../sample_comp/product_listing';
import Front_Header from './front_header';
import Footer from './footer';
import Top_Banner from '../sample_comp/top_banner';
import Top_Specified from './top_specified';
import Home_Product from './home_product';
import Product_Slider from '../sample_comp/product_slider';
import Features_Slider  from '../sample_comp/features_slider';
import Feature_Web_List from '../sample_comp/feature_web_list';
import Article_Listing, { Article_Listing_2,Article_Listing_Arrow } from '../sample_comp/article_listing';
import Home_Banner from '../sample_comp/home_banner';
import Article_Blogs from '../sample_comp/article_blogs';
import {Left_Img_Txt_Desc,Left_Txt_Txt_Desc,Text_Image_Full} from '../sample_comp/left_img_txt_desc';
import Single_Img_Msg from '../sample_comp/single_img_msg';
import Txt_Description from '../sample_comp/txt_description';
import ImageGallery from '../sample_comp/image_gallery';
import Video_Slider from '../sample_comp/video_slider';
import Collapse_Pan from '../sample_comp/collapse_pan';
import Feature_Card from '../sample_comp/feature_card';
import Testimonials from '../sample_comp/testimonials';
import Blog_Listing,{Blog_Listing_2} from '../sample_comp/blog_listing';
import Icon_Title from '../component_version2/icon_title';
import { Footer_Options } from '../component_version2/footer_options';
import Text_Thumbnail from '../component_version2/text_thumbnail';
import Image_Collarge_Grid from '../component_version2/image_collarge_grid';
import Image_Grid from '../component_version2/image_grid';
import Comp_Subscribe_Email from '../sample_comp/comp_subscribe_email';
import Comp_Recent_Blog from '../sample_comp/comp_recent_blog';
import {Comment_List} from '../component_version2/comment_list';
import {Site_Map_Component} from '../component_version2/site_map_component';

import {Comp_Submit_Comments} from '../component_version2/comp_submit_comments';
import {Comp_Contact_Submit} from '../component_version2/comp_contact_submit';
// not workable component as below--->
//import { Dual_Text_Card } from '../sample_comp/dual_text_card';

import { Player } from 'video-react';
import AOS from 'aos';

import titleVid from '../assets/videos/imp_water.mp4';

//import titleVid from '../assets/images/backg.gif';
import './index.css';
/* Media */
import logoImg from '../assets/images/logo/logo.png';

import ban1 from '../assets/images/slider-image/02.jpg';
import ban2 from '../assets/images/slider-image/02.jpg';

import why_medi from '../assets/images/icons/capsule.png';
import why_edu from '../assets/images/icons/fist.png';
import why_care from '../assets/images/icons/heart.png';
import why_confi from '../assets/images/icons/discretion.png';
import why_trans from '../assets/images/icons/eye.png';

import pImg1 from '../assets/images/product-image/AsthmaticaOil.png'
import Left_Menu_Option from '../sample_comp/left_menu_option';
import Blogs from '../sample_comp/blogs';
import Time_Line from '../sample_comp/time_line';


import Footer_Register from '../sample_comp/footer_register';
import Comp_Panchang from '../sample_comp/comp_panchang';

import { renderToString } from 'react-dom/server';
import '../sample_comp/why_us.css';

let loginUser="";
let userObject="";
let clsObject="";
let loc="";
let products="";
let rightBarLists;

let _topTitle="";
let _topKeywords="";
let _topDescription="";

let _preData=[];
let _preChildData=[];
let _preConent="";
let _preBrief="";
let _preCommentData="";
let _preRecentArticles="";//this section only used for nonHOME Pg

let clientIp="127.0.0.1";
//for adzoic
let strInjAd_102="<div id=\"ezoic-pub-ad-placeholder-102\"></div>";
let strInjAd_122="<div id=\"ezoic-pub-ad-placeholder-122\"> </div>";
//let strInjAdd_137="<div id=\"ezoic-pub-ad-placeholder-137\"> </div>";//floating ads
let strInjAdd_137="<div id=\"ezoic-pub-ad-placeholder-141\"></div>";//floating ads


class Index extends React.Component{
   
    constructor(props){
        super(props);
        this.state={viewData:"0"};
        this.multiselectRef = React.createRef();
        this.state={ top_title:"",top_keywords:"",top_desc:""};
        loc=props.location["pathname"].substr(1);
        //alert(JSON.stringify(this.props.match.params[1]));
        //alert(loc);
        if(localStorage.getItem("userData")!=null){
          //alert(localStorage.getItem("userData"));
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
         var listRoles= UserToken.getAccessRoles();
          this.setMetaTags(listRoles);
          if(userObject){
            //loginUser=userObject[0].person_name;
          }
          rightBarLists= getRoleDetails(listRoles,loc);
         
          //window.location.href="./Dashboard";
        }
      }
 /* callback for comment and contactus */
 callBackCommentRating(data,msg){
    if(data==="001"){
        alert(msg);
        window.location.reload();
    }

 }
 callBackContactFrm(data,msg){
    if(data==="001"){
        alert(msg);
        window.location.reload();
    }
 }     
getPreData(){
    var mapCat=[];
  //alert(categoryParam);
 //alert(clientIp);
//   alert(categoryParam);
  mapCat.push({name:"curl_type",value:"getPageUrlData"});
  mapCat.push({name:"res_func",value:"callBackProList"});
  mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
  mapCat.push({name:"pg_name",value:loc});
  mapCat.push({name:"client_ip",value:clientIp});
 // mapCat.push({name:"emp_id",value:userObject[0].id});
  mapCat["res_func"]="callBackProList";
  mapCat["curl_type"]="getPageUrlData";
  CallMapMethod_CBack(clsObject,mapCat,function(data,msg){
    //alert(data);
    //alert(msg);
   
    if(msg!=="001"){
        _preConent= $($($.parseHTML(msg)).filter("#resContent")).html();
        
        _preBrief=$($($.parseHTML(msg)).filter("#resBrief")).html();
       
       
      //alert(data);

        //alert("check brief testing"+JSON.stringify(_preBrief));

        let jsonList=JSON.parse(data);
    
        //alert("i m in condition");
        _preData=jsonList.main_data;
        _preCommentData=jsonList.comment_data;
        _preRecentArticles=jsonList.recent_articles;
        _preChildData=jsonList["child_data"];
        //alert(JSON.stringify(jsonList.counter));
        $("#online_counter").html("Online Visitors: <b>"+jsonList.counter.online+"</b>");
        $("#today_counter").html("Todays Visitors: <b>"+jsonList.counter.day_value+"</b>");
        $("#yesterday_counter").html("Yesterday Visitors: <b>"+jsonList.counter.yesterday_value+"</b>");
        $("#week_counter").html("Weekly Visitors: <b>"+jsonList.counter.week_value+"</b>");
        $("#total_counter").html("Total Visitors: <b>"+jsonList.counter.all_value+"</b>");
        $("#record_counter").html("Record of: <b>"+jsonList.counter.record_value+",Date: "+jsonList.counter.record_date+"</b>");

    }

    var mapCat=[];
  if(loc===""){
      loc="home";
  }
  var pgMap=[];
  pgMap.push({name:"curl_type",value:"getPgData"});
  pgMap.push({name:"res_func",value:"callBackpgData"});
  pgMap.push({name:"loc",value:loc});

  /* only for product */
  //pgMap.push({name:"multi_get_prod",value:"d"});
  pgMap.push({name:"p1",value:"174,162,163,164,165,166,167"});
  pgMap.push({name:"p2",value:"0"});
  //mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
  //mapCat.push({name:"emp_id",value:userObject[0].id});
  pgMap["res_func"]="callBackpgData";
  pgMap["curl_type"]="getPgData";
  CallCMSMapMethod(clsObject,pgMap);
    
  });
}
 getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    //console.log(res.data);
    //setIP(res.data.IPv4)
    clientIp=res.data.IPv4;
    clsObject.getPreData();
  }      
componentDidMount(){
   // alert("Init Data");
  clsObject=this;

  
 this.getData();
  
  AjaxCallMethod(clsObject,"okSuccCNOT");
  //$("#article_listing_child")
  
  
}
setMetaTags(listMeta){
   // alert(JSON.stringify(listMeta));

    for(var m=0;m<listMeta.length;m++){
        if(loc===""){
            loc="home";
        }
       if(listMeta[m]["col2"]===loc){
           //alert(listMeta[m]["col2"]);
           _topTitle=listMeta[m]["col3"];
           _topKeywords=listMeta[m]["col4"];
           _topDescription=listMeta[m]["col5"];
        document.querySelector('meta[name="title"]').setAttribute("content", _topTitle);
        document.querySelector('meta[name="description"]').setAttribute("content", _topDescription);
        document.querySelector('meta[name="keywords"]').setAttribute("content", _topKeywords);
       }
      
    }
    
}
callBackpgData(data,msg){
    //alert("testing of2 "+data);
  
    //let listObj=JSON.parse(data);
    var listObj= JSON.parse("{\"access\":"+data+"}");
    
    // alert(JSON.stringify(listObj.access));
    //   $("#pgComp").html(JSON.stringify(listObj.access));
    //   return;
    //alert("successfully parse");
    var mainHt="";
    let isHome=false;
    let isWhite=false;
    //alert(loc);
    if(loc==="home"){
        isHome=true;
        isWhite=true;
       // mainHt+=Video_Banner_Comp();
    }

    var injextI=0;
    var injCondition=false;
    var ifImgGallery=false;
    
    var strTopBanner="";

    let strTestimonials="";
    for(var _dlist=0;_dlist<100;_dlist++){
     
        if(listObj.access[_dlist]){
          
            if(listObj.access[_dlist].top_banner){
               // alert("I am in conditio");
               injCondition=true;
               _topTitle=listObj.access[_dlist].top_banner[0]["top_title"];
               strTopBanner=Top_Banner(listObj.access[_dlist].top_banner,isHome);
            }
            else if(listObj.access[_dlist].home_banner){
               // alert("i am in cond..");
               injCondition=true;
               //alert(JSON.stringify(listObj.access[_dlist].home_banner[0]));
               _topTitle=listObj.access[_dlist].home_banner[0]["top_title"];
                mainHt+=Home_Banner(listObj.access[_dlist].home_banner,"1","adscript");
            }else if(listObj.access[_dlist].dual_text_card){
                injCondition=true;
                //alert("I m in dual text card");
                //var dbs=render(){return(new Dual_Banner(listObj.access[_dlist].dual_text_card)};
                mainHt+=Left_Txt_Txt_Desc(listObj.access[_dlist].dual_text_card);
            }else if(listObj.access[_dlist].text_image_full){
                injCondition=true;
                //alert("I m in dual text card");
                //var dbs=render(){return(new Dual_Banner(listObj.access[_dlist].dual_text_card)};
                mainHt+=Text_Image_Full(listObj.access[_dlist].text_image_full);
            }
            else if(listObj.access[_dlist].single_img_text){
                injCondition=true;
                _topTitle=listObj.access[_dlist].single_img_text[0]["top_title"];
                mainHt+=Single_Img_Msg(listObj.access[_dlist].single_img_text);
            }else if(listObj.access[_dlist].img_text_desc_img_left){
                injCondition=true;
                _topTitle=listObj.access[_dlist].img_text_desc_img_left[0]["top_title"];
                mainHt+=Left_Img_Txt_Desc(listObj.access[_dlist].img_text_desc_img_left,false,isWhite);
            }else if(listObj.access[_dlist].img_text_desc_img_right){
                injCondition=true;
                _topTitle=listObj.access[_dlist].img_text_desc_img_right[0]["top_title"];
                mainHt+=Left_Img_Txt_Desc(listObj.access[_dlist].img_text_desc_img_right,true,isWhite);
            }else if(listObj.access[_dlist].text_description){
                injCondition=true;
                //alert("i am in text");
                //alert(Txt_Description(listObj.access[_dlist].text_description));
                mainHt+=Txt_Description(listObj.access[_dlist].text_description);
            }else if(listObj.access[_dlist].list_slider){
                injCondition=true;
                mainHt+=Article_Blogs(listObj.access[_dlist].list_slider);
            }else if(listObj.access[_dlist].image_gallery){
                injCondition=true;
                ifImgGallery=true;
                mainHt+=ImageGallery(listObj.access[_dlist].image_gallery);
            }else if(listObj.access[_dlist].video_slider){
                //alert("I am in testing");
                injCondition=true;
                mainHt+=Video_Slider(listObj.access[_dlist].video_slider);
            }
            else if(listObj.access[_dlist].blogs){
                
                injCondition=true;
                mainHt+=Blogs(listObj.access[_dlist].blogs);
            }else if(listObj.access[_dlist].feature_web_list){
                injCondition=true;
                //alert("I am in feature "+listObj.access[_dlist].feature_web_list);
                mainHt+=Feature_Card(listObj.access[_dlist].feature_web_list);
            }else if(listObj.access[_dlist].article_listing){
                injCondition=true;
                //alert("I am in feature "+listObj.access[_dlist].feature_web_list);
                //mainHt+=Article_Listing(listObj.access[_dlist].article_listing);
                mainHt+=Article_Listing_2(listObj.access[_dlist].article_listing,"1");
            }else if(listObj.access[_dlist].article_listing_arrow){
                injCondition=true;
                mainHt+=Article_Listing_Arrow(listObj.access[_dlist].article_listing_arrow);
            }
            else if(listObj.access[_dlist].icon_title){
                injCondition=true;
                mainHt+=Icon_Title(listObj.access[_dlist].icon_title);
            }else if(listObj.access[_dlist].text_thumbnail){
                injCondition=true;
                // alert("i m in text thumb");
                mainHt+=Text_Thumbnail(listObj.access[_dlist].text_thumbnail);
            }
            else if(listObj.access[_dlist].blog_listing){
                injCondition=true;
                mainHt+=Blog_Listing_2(listObj.access[_dlist].blog_listing);
            }
            else if(listObj.access[_dlist].collapse_pan){
                injCondition=true;
                mainHt+=Collapse_Pan(listObj.access[_dlist].collapse_pan);
            }else if(listObj.access[_dlist].time_line){
                injCondition=true;
                mainHt+=Time_Line(listObj.access[_dlist].time_line);
            }else if(listObj.access[_dlist].testimonials){
                injCondition=true;
                
                strTestimonials=Testimonials(listObj.access[_dlist].testimonials,-1,true,"one-element");
                mainHt+=Testimonials(listObj.access[_dlist].testimonials);
            }else if(listObj.access[_dlist].top_info_slider){
                   injCondition=true;
                   //alert("i am in topindo");
                   $("#top_msg_bar").html(listObj.access[_dlist].top_info_slider[0]["col2"]);
            }else if(listObj.access[_dlist].footer_options){
                injCondition=true;
                mainHt+=Footer_Options(listObj.access[_dlist].footer_options);
            }else if(listObj.access[_dlist].image_collarge_grid){
                injCondition=true;
                mainHt+=Image_Collarge_Grid(listObj.access[_dlist].image_collarge_grid);
            }else if(listObj.access[_dlist].image_grid){
                injCondition=true;
                mainHt+= Image_Grid(listObj.access[_dlist].image_grid);
            }
            if(injCondition){
                injextI++;
            }
            if(injextI==1){
                //alert("check in condi == "+loc)
                if(loc==="home"){
                    //mainHt+=Features_Slider();
                    //alert(JSON.stringify(listObj.product));
                    this.callBackProList(JSON.stringify(listObj.product));
                    //alert(products);
                    mainHt+=products;
                }
           }
          
        }

      
        //$("#pgComp").innerHtml=mainHt;
    }
    if(loc.indexOf("library/")!==-1){
        mainHt+=products;
    }
    //alert(_topTitle);
   
    //alert(mainHt);
    //mainHt+=Product_Hori_Slider();

    //Condition for leftMenuOpt
    if(rightBarLists){
        var getLeftOpt=Left_Menu_Option(rightBarLists,loc);
    var mainDiv="<div class=\"shop-category-area pb-100px pt-70px\">"+
    "<div class=\"container\">"+
        "<div class=\"row\">";
    var mainCloseDiv="</div></div></div>";
    mainHt="<div class=\"col-lg-9 order-lg-last col-md-12 order-md-first\">"+
            mainHt+"</div>";
    mainHt=mainDiv+getLeftOpt+mainHt+mainCloseDiv;
      }
   mainHt=strTopBanner+mainHt;
  
   //let testing=Image_Collarge_Grid();

    $("#pgComp").append(mainHt+strInjAd_122);

    /* Write components */
    if(loc==="home"){
        //let subsc=renderToString(<Comp_Subscribe_Email/>);
        let subsc="<a href=\"../Astro-Ayurveda\" class=\"add-cart btn btn-primary btn-hover-primary mt-2 w-100\">Astrology Teaching Classes</a>"+
        "<a href=\"https://www.astrosaxena.in/services/\" class=\"add-cart btn btn-primary btn-hover-primary mt-2 w-100\">Astrology Consultation</a>";
        
        // alert(JSON.stringify(subsc));
       
       $("#home_banner_child").append(strInjAdd_137+strInjAd_102+strTestimonials+subsc);
       //alert("data to assign"+JSON.stringify(_preData));
       let recentBlog=renderToString(<Comp_Recent_Blog blogData={_preData} />);

       //alert(recentBlog);
       let subListSlid=renderToString(<Comp_Panchang/>);
       //alert(subListSlid);
       $("#article_listing_child").append(recentBlog+subListSlid);
    }else if(loc!=="sitemap"){
        //alert(loc);
        //for non home
        let preList=[];
        let col1="";
        let col2="";
        let col3="";
        let col4="";
        let newPath="";
        if(loc!=="contact"){
            if(_preData.length>0){
                col1=_preData[0]["col1"];
                col2=_preConent;
                col3=_preBrief;
                col4=_preData[0]["col4"];
                newPath=appGetUrlPath();
            }
        }else{
            
                col1="";
                col2=_preConent;
                col3=_preBrief;
                col4="";
                newPath=appGetUrlPath();
            
        }
       
        //alert("from bre"+_preBrief);
      
        preList.push({col1:col1,col2:col2,col3:col3,col4:col4,col5:"",col7:"",col12:""});
       let txtStrLand= Text_Image_Full(preList,false,newPath);
       
       let preChildList=[];
       for(let i=0;i<_preChildData.length;i++ ){
        preChildList.push({col1:_preChildData[i]["title"],col2:"",col3:"",
                      col4:_preChildData[i]["image"],col7:"",col10:"",bg_img:"",g_title:"",e_id:_preChildData[i]["pageurlname"]});
       };

       //alert(JSON.stringify(_preChildData));

       let childStr=Article_Listing_2(preChildList,"0",newPath);
       if(preChildList.length==0){
        let _article_list_ads_134="<div class=\"col-md-12\"><div id=\"ezoic-pub-ad-placeholder-134\"></div></div>";
        let _article_list_ads_133="<div class=\"col-md-12\"><div id=\"ezoic-pub-ad-placeholder-133\"></div></div>";
      
        childStr+=_article_list_ads_134+_article_list_ads_133;
       }
       
       //renderToString()
       let strComment=renderToString(Comment_List(_preCommentData));
       //alert(strComment);
       $("#display_comments").html(strComment);

       $("#pgComp").append(strInjAd_122+txtStrLand+childStr);

      

  // alert(JSON.stringify(subsc));
         let subsc="<a href=\"../Astro-Ayurveda\" class=\"add-cart btn btn-primary btn-hover-primary mt-2 w-100\">Astrology Teaching Classes</a>"+
         "<a href=\"https://www.astrosaxena.in/services/\" class=\"add-cart btn btn-primary btn-hover-primary mt-2 w-100\">Astrology Consultation</a>";
         let recentBlog=renderToString(<Comp_Recent_Blog blogData={_preRecentArticles} />);
        
         $("#home_banner_child").append(strInjAdd_137+subsc+recentBlog);
       //alert(txtStrLand);
    }else if(loc==="sitemap"){
        //alert(JSON.stringify(_preData));
        let strSiteMap=renderToString(Site_Map_Component(_preData));
        $("#pgComp").append(strSiteMap);
        let subsc="<a href=\"../Astro-Ayurveda\" class=\"add-cart btn btn-primary btn-hover-primary mt-2 w-100\">Astrology Teaching Classes</a>"+
        "<a href=\"https://www.astrosaxena.in/services/\" class=\"add-cart btn btn-primary btn-hover-primary mt-2 w-100\">Astrology Consultation</a>";
        let recentBlog=renderToString(<Comp_Recent_Blog blogData={_preRecentArticles} />);
       $("#home_banner_child").append(strInjAdd_137+subsc+recentBlog);
    }

    /* for video tmp */
    let vid = document.getElementById("vid_home");
    //alert(vid);
    if(typeof vid!=="undefined" && vid!==null){
        //alert("i m in condition");
        vid.currentTime = 2;
    }
    

    RespBkMthdMapp(clsObject,"_addToCart","setCartBk");

    AOS.init();
    AOS.init({
        once: true
      });
    // AOS.init({
    //   once: true,
    //   duration: 1000
    // });
   
     
    const script = document.createElement("script");
    script.src = "/main.js";
    script.async = true;
    //script.onload = () => clsObject.scriptLoaded();
  
    document.body.appendChild(script);

    


    
      //this.forceUpdateHandler();
    if(ifImgGallery){
        $('.gallery-item').magnificPopup({
            type: 'image',
            gallery: {
                enabled: true
            }
        });
    }
   
    
    
    const astrScript=document.createElement("script");
    astrScript.src="https://www.indianastrologysoftware.com/widget/wpanchang/av.tools.widget.big.db.v11.js";
    astrScript.async=true;
    document.body.appendChild(astrScript);
    
        
    astrScript.onload = function() {
        var widget = new window.avWidgetAstroCalendar("astro_widget","free");
      };
    
 // /* astrology */
        
        //window.adsParamets="101,102,122,137";
        window.adsParamets="102,122,141,136,135,134,133";
        if(loc==="home"){
            window.adsParamets="102,122,141,136,135,134,133";
        }else if(loc!=="sitemap"){
            //window.adsParamets="101,102,122,137";
            //window.adsParamets="101,102,122,137,136,135,134,133";
        }
        window.ezstandalone = window.ezstandalone || {};
        window.ezstandalone.cmd = window.ezstandalone.cmd || [];
        window.ezstandalone.cmd.push(function() {
        //window.ezstandalone.displayMore(window.adsParamets);
        //alert(window.adsParamets);

        window.ezstandalone.define(window.adsParamets);
        if (!window.ezstandalone.enabled){
            window.ezstandalone.enable();
            window.ezstandalone.display();
        }else{
            window.ezstandalone.refresh();
        }
        
        //window.ezstandalone.displayMore(window.adsParamets);
        
    });
  
}

setCartBk(refId){
   // alert(refId);
    this.setState(state =>({viewData:refId}));
    this.forceUpdate();
    $("#offcanvas-cart").addClass("offcanvas-open");
  }
callBackProList(data,msg){
    if(data!=="0"){
     let resPs="";

    //  resPs+= Product_Slider(data,"174","Sanjana Seeds");
    //  resPs+= Product_Slider(data,"163","Mobile");
    //  resPs+= Product_Slider(data,"164","Computer & Laptops");
    //  resPs+= Product_Slider(data,"162","Android Tv");
    //  resPs+= Product_Slider(data,"166","Air Conditioners");

     products+=resPs;
    }
  
  }
saveEdu(){
  GetImgCertUser(clsObject,"#frmEdu","getTest","okEdut");
}
okEdut(data,datares,userResp){
  //alert(data);
}
    render(){
        return(<div>
          <Front_Header loc={loc}/>

          <div className="offcanvas-overlay"></div>
        {loc==="home"?<div id="pgComp">
        <div id="ezoic-pub-ad-placeholder-101"></div>
       
        
</div>:<div className="row container">
    <div className="col-md-8">
    <div id="pgComp">
      
    <div id="ezoic-pub-ad-placeholder-101"></div>
    
</div>  
    </div>
    <div id="home_banner_child" className="col-md-4">
    <div id="ezoic-pub-ad-placeholder-102"></div>
    </div>
    <div className="col-lg-8 description-review-bottom">
                         {loc!=="contact"?<Comp_Submit_Comments loc={loc}/>:<Comp_Contact_Submit/>}  
                            {/* comment display */}
                            <div id="display_comments">

                            </div>
                        </div>
    </div>}
         
        
        


        {/* <Footer_Register/> */}
         <Footer/>
    

          
          <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog">
          <div className="modal-dialog" role="document">
              <div className="modal-content">
                  <div className="modal-header">
                      <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><span
                              aria-hidden="true">x</span></button>
                  </div>
                  <div className="modal-body">
                      <div className="row">
                          <div className="col-md-5 col-sm-12 col-xs-12 mb-lm-30px mb-sm-30px">
                              
                              <div className="swiper-container gallery-top mb-4">
                                  <div className="swiper-wrapper">
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/1.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/2.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/3.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/4.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/5.jpg" alt=""/>
                                      </div>
                                  </div>
                              </div>
                              <div className="swiper-container gallery-thumbs slider-nav-style-1 small-nav">
                                  <div className="swiper-wrapper">
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/1.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/2.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/3.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/4.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/5.jpg" alt="" />
                                      </div>
                                  </div>
                                 
                                  <div className="swiper-buttons">
                                      <div className="swiper-button-next"></div>
                                      <div className="swiper-button-prev"></div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-md-7 col-sm-12 col-xs-12">
                              <div className="product-details-content quickview-content">
                                  <h2>Originals Kaval Windbr</h2>
                                  <p className="reference">Reference:<span> demo_17</span></p>
                                  <div className="pro-details-rating-wrap">
                                      <div className="rating-product">
                                          <i className="ion-android-star"></i>
                                          <i className="ion-android-star"></i>
                                          <i className="ion-android-star"></i>
                                          <i className="ion-android-star"></i>
                                          <i className="ion-android-star"></i>
                                      </div>
                                      <span className="read-review"><a className="reviews" href="#">Read reviews (1)</a></span>
                                  </div>
                                  <div className="pricing-meta">
                                      <ul>
                                          <li className="old-price not-cut">$18.90</li>
                                      </ul>
                                  </div>
                                  <p className="quickview-para">Lorem ipsum dolor sit amet, consectetur adipisic elit eiusm tempor incidid ut labore et dolore magna aliqua. Ut enim ad minim venialo quis nostrud exercitation ullamco</p>
                                  <div className="pro-details-size-color">
                                      <div className="pro-details-color-wrap">
                                          <span>Color</span>
                                          <div className="pro-details-color-content">
                                              <ul>
                                                  <li className="blue"></li>
                                                  <li className="maroon active"></li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="pro-details-quality">
                                      <div className="cart-plus-minus">
                                          <input className="cart-plus-minus-box" type="text" name="qtybutton" value="1" />
                                      </div>
                                      <div className="pro-details-cart btn-hover">
                                          <button className="add-cart btn btn-primary btn-hover-primary ml-4"> Add To
                                              Cart</button>
                                      </div>
                                  </div>
                                  <div className="pro-details-wish-com">
                                      <div className="pro-details-wishlist">
                                          <a href="wishlist.html"><i className="ion-android-favorite-outline"></i>Add to
                                              wishlist</a>
                                      </div>
                                      <div className="pro-details-compare">
                                          <a href="compare.html"><i className="ion-ios-shuffle-strong"></i>Add to compare</a>
                                      </div>
                                  </div>
                                  <div className="pro-details-social-info">
                                      <span>Share</span>
                                      <div className="social-info">
                                          <ul>
                                              <li>
                                                  <a href="#"><i className="ion-social-facebook"></i></a>
                                              </li>
                                              <li>
                                                  <a href="#"><i className="ion-social-twitter"></i></a>
                                              </li>
                                              <li>
                                                  <a href="#"><i className="ion-social-google"></i></a>
                                              </li>
                                              <li>
                                                  <a href="#"><i className="ion-social-instagram"></i></a>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          </div>
          </div>
          
          );
    }
}
export default Index