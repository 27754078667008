import  $ from 'jquery';
import React from 'react';
import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";
import UserToken from '../function_lib/userToken';
import {getAccessRolesNew,CallCMSMapMethod} from '../function_lib/common_cms';
import {RespBkMthdMapp,setKeyUpMapp,CallMapMethod} from '../function_lib/common_lib';
import {CityList,tmpAutoList} from '../function_lib/city_india';
import './front_header.css';
import logoImg from '../assets/images/logo/logo.png';

let listRoles;
let loginUser;
let userObject;

let logInObj;
let userOpt;
let clsObject;
let personName;


let _topTitle="";
let _topKeywords="";
let _topDescription="";
let loc="";
class Front_Header extends React.Component{

    GetClock(){
      let  tday=new Array("Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday");
let tmonth=new Array("January","February","March","April","May","June","July","August","September","October","November","December");
        var d=new Date();
        var nday=d.getDay(),nmonth=d.getMonth(),ndate=d.getDate(),nyear=d.getYear();
        if(nyear<1000) nyear+=1900;
        var nhour=d.getHours(),nmin=d.getMinutes(),nsec=d.getSeconds(),ap;
        
        if(nhour==0){ap=" AM";nhour=12;}
        else if(nhour<12){ap=" AM";}
        else if(nhour==12){ap=" PM";}
        else if(nhour>12){ap=" PM";nhour-=12;}
        
        if(nmin<=9) nmin="0"+nmin;
        if(nsec<=9) nsec="0"+nsec;
        
        document.getElementById('clockbox').innerHTML=""+tday[nday]+", "+tmonth[nmonth]+" "+ndate+", "+nyear+" "+nhour+":"+nmin+":"+nsec+ap+"";
        //document.getElementById('clockbox_mob').innerHTML=""+tday[nday]+", "+tmonth[nmonth]+" "+ndate+", "+nyear+" "+nhour+":"+nmin+":"+nsec+ap+"";
    
        }
        
      

    logout(rf){
        localStorage.clear();
        window.location.href="./";
      }
      componentDidUpdate(props){
          //alert(JSON.stringify(this.props));
          if(typeof this.props.checkFdata!=="undefined" && this.props.checkFdata!==null && this.props.checkFdata!==""){
            //alert(JSON.stringify());
            let keyToken="";
            Object.keys(this.props.checkFdata).forEach(function(key) {
               keyToken=key;
                // var value = this.props.checkFdata[1][key];
            
            });
            if(_topTitle===""){
                _topTitle=this.props.checkFdata[keyToken][0]["top_title"];
                _topKeywords=this.props.checkFdata[keyToken][0]["top_keyword"];
                _topDescription=this.props.checkFdata[keyToken][0]["top_desc"];
                document.title=_topTitle;
             document.querySelector('meta[name="title"]').setAttribute("content", _topTitle);
             document.querySelector('meta[name="description"]').setAttribute("content", _topDescription);
             document.querySelector('meta[name="keywords"]').setAttribute("content", _topKeywords);
            }
           //alert(JSON.stringify(this.props.checkFdata[1][keyToken]));
          }
          //alert("now updates");
      }
    constructor(props){
       super(props);
      loc=this.props.loc;
      
     
       if(localStorage.getItem("userDS")!=null){
        logInObj=JSON.parse(localStorage.getItem("userDS"));
        personName=logInObj[0]["first_name"];
        if(personName.trim()===""){
           personName="My Account";
        }
      }
    }
    gb_search(ref){
        if(ref!==""){
         var map=[];
         map.push({name:"curl_type",value:"getSearchAuto"});
     map.push({name:"res_func",value:"callBackNg"});
     map.push({name:"sec",value:"27KeHNL8l225KpBePgi8Hg=="});
     map.push({name:"loc_ser",value:ref})
     map["res_func"]="callBackNg";
     map["curl_type"]="getSearchAuto";
     CallCMSMapMethod(this,map);
        }else{
         //$("#gb_item_search").html(""); 
        }
     }
     callBackNg(data,msg){
        //alert(data);
        //return;
        if(data.trim()!=="0"){
            var listVal= JSON.parse(data);
            //alert(listVal.length);
            var strList="";
            $("#txtSearchHead").autocomplete({
               source:listVal,
               select:function(event,ui){
                   event.preventDefault();
                   if(ui.item.menu==="Blogs"){
                       window.location.href="../blog/"+ui.item.label.replace(/\s/g, '_')+"?rf="+ui.item.e_id;
                   }else if(ui.item.menu==="Product"){
                    window.location.href="../product/"+ui.item.label.replace(/\s/g, '_')+"?rf="+ui.item.e_id;
                   }
                   //alert(ui.item.category_name);
                   //if(event)
                   //alert("i am in select");
               }
             });

             $("#txtSearchHead_sm").autocomplete({
               source:listVal,
               select:function(event,ui){
                   event.preventDefault();
                   if(ui.item.menu==="Blogs"){
                    window.location.href="../blog/"+ui.item.label.replace(/\s/g, '_')+"?rf="+ui.item.e_id;
                }else if(ui.item.menu==="Product"){
                 window.location.href="../product/"+ui.item.label.replace(/\s/g, '_')+"?rf="+ui.item.e_id;
                }
                   //alert(ui.item.category_name);
                   //if(event)
                   //alert("i am in select");
               }
             });
        }
    }
    componentDidMount(){
        clsObject=this;
        
        setInterval(() => {
            clsObject.GetClock();
          }, 1000);
        
        
        var mapCat=[];



        mapCat.push({name:"curl_type",value:"getAccessToken"});
        mapCat.push({name:"res_func",value:"callBackAccess"});
       // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
        //mapCat.push({name:"emp_id",value:userObject[0].id});
        mapCat["res_func"]="callBackAccess";
        mapCat["curl_type"]="getAccessToken";
        CallCMSMapMethod(this,mapCat);

        userOpt="<button class=\"dropdown-toggle header-action-btn\" data-bs-toggle=\"dropdown\">"+
        "<i class=\"icon-user\"></i></button>"+
                                        "<ul class=\"dropdown-menu dropdown-menu-right\">"+
                                            //"<li><a class=\"dropdown-item\" href=\"#\">My Cart</a></li>"+
                                            //"<li><a class=\"dropdown-item\" href=\"#\">Checkout</a></li>"+
                                            "<li><a class=\"dropdown-item\" href=\"/login\">Sign in</a></li>"+
                                        "</ul>";
            if(logInObj)
            {
                         userOpt= "<button class=\"dropdown-toggle pr-0 border-0 header-action-btn\" data-bs-toggle=\"dropdown\">"+
                                        "<a href=\"javascript:void(0)\">"+"<i class=\"ion-ios-arrow-down\"></i>User"+"</a>"+"</button>"+
                                                                            "<ul class=\"dropdown-menu dropdown-menu-right\">"+
                                                                                "<li><a class=\"dropdown-item\" href=\"./my_orders\">"+personName+"</a></li>"+
                                                                                // "<li><a class=\"dropdown-item\" href=\"./my_profile\">My account</a></li>"+
                                                                                "<li><a class=\"dropdown-item\" href=\"./my_orders\">My Orders</a></li>"+
                                                                                // "<li><a class=\"dropdown-item\" href=\"#\">My Cart</a></li>"+
                                                                                "<li><a class=\"dropdown-item _setbackparam\" param1=\"1\"  href=\"javascript:void(0)\">Logout</a></li>"+
                                                                            "</ul>";   
                                 
                                        }
  //alert(userOpt);
  
  setKeyUpMapp(this,"gb_search");

  var locCityList=CityList();
 
  $("#txtSearchHead").autocomplete({
   source:locCityList
 });

 //userOpt="";
//   $("#user_opt_desk").html(userOpt);
//   $("#user_opt_mob").html(userOpt);
  RespBkMthdMapp(clsObject,"_setbackparam","logout")

    }
    setMetaTags(listMeta){
        // alert(JSON.stringify(listMeta));
        // alert(JSON.stringify(this.props));
         for(var m=0;m<listMeta.length;m++){
             if(typeof loc==='undefined'|| loc===""){
                 loc="home";
             }
            
            if(listMeta[m]["col2"].toUpperCase()===loc.toUpperCase() || listMeta[m]["col2"].toUpperCase()==="../"+loc.toUpperCase()){
                //alert(listMeta[m]["col2"]);
                //alert(listMeta[m]["col2"]+"=="+loc);
                _topTitle=listMeta[m]["col3"];
                _topKeywords=listMeta[m]["col4"];
                _topDescription=listMeta[m]["col5"];
                document.title=_topTitle;
             document.querySelector('meta[name="title"]').setAttribute("content", _topTitle);
             document.querySelector('meta[name="description"]').setAttribute("content", _topDescription);
             document.querySelector('meta[name="keywords"]').setAttribute("content", _topKeywords);
            }
           
         }
         
     }
    callBackAccess(data,msg){
        //alert("cback"+data);
        if(data!=="0"){
            localStorage.setItem('userData',data);
            UserToken.setUserObject(localStorage.getItem("userData"));
            listRoles= UserToken.getAccessRoles();
            this.setMetaTags(listRoles);
          var listHtm=  getAccessRolesNew(listRoles); 
          $("#menu_opt").html(listHtm);
          $("#mob_menu").html(listHtm);
        }
    }
    render(){
        return(<div>
            <div className="  header section">
            <div className="header-top section-fluid bg-black">
            <div className="container_top">
                <div className="row d-flex mt-1 mb-1 align-items-center">
                    
                    <div className="col text-center text-lg-left">
                        <div className="header-top-massege">
                            <p>Swami Premanad Bharti</p>
                        </div>
                    </div>
                    <div className="col flex-grow-2 text-center text-lg-left">
                        <div className="header-top-massege1">
                            
                            <p id="top_msg_bar"></p>
                           
                        </div>
                    </div>
                    
                    <div className="col d-none d-lg-block">
                        <div className="header-top-set-lan-curr d-flex justify-content-end">
                            
                            <div className="header-top-curr dropdown">
                                <button className="dropdown-toggle" data-bs-toggle="dropdown"> Language : (ENG) <i
                                        className="ion-ios-arrow-down"></i></button>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li><a className="dropdown-item" href="#">English</a></li>
                               </ul>
                            </div>
                           
                          
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div className="header-bottom d-none d-lg-block">
            <div className="container_top position-relative">
                <div className="d-flex justify-content-center">
                   
                    <div className="col-auto align-self-center">
                        <div className="header-logo">
                            <a href="/"><img className='logo_desk' src={logoImg} alt="Site Logo" /></a>
                        </div>
                    </div>
                    
                    {/* <div className="p2 custom-search input-group">
    <div className="input-group-text p-0">
        <select className="form-select form-select-lg shadow-none border-0">
            <option value="0">All</option>
            <option value="1">Products</option>
            <option value="2">Blogs</option>
            <option value="3">Ingrediant</option>
        </select>
    </div>
    <input type="text" autoComplete='off' id="txtSearchHead" name="txtSearchHead" className="form-control _setSearchKey" placeholder="Search Here" />
    <button className="input-group-text shadow-none px-4 btn-warning">
    <i className="icon-magnifier"></i>
    </button>
</div> */}
                    
                    <div className="col align-self-center">
                        <div className="header-actions">
                            <div className="header_account_list">
                                <a href="javascript:void(0)" className="header-action-btn search-btn"><i
                                        className="icon-magnifier"></i></a>
                                <div className="dropdown_search">
                                    <div className="input-group">
                                    <div className="input-group-prepend">
    <span class="input-group-text"><select className="form-control">
                                          <option value="all">All</option>
                                      </select></span>
  </div>
                                      
                                
                                        <input className="form-control" placeholder="Enter your search key" type="text" />
                                        <button className="submit" type="submit"><i className="icon-magnifier"></i></button>
                                    </div>
                               
                                </div>
                            </div>
                           
                            <div id="user_opt_desk" className="header-bottom-set dropdown">
                               
                            </div>
                            <a href="#"><i class="fa fa-phone"></i> +91 7207658536 (Timing to call - 11 am IST to 8 PM IST)</a>
                            <span id="clockbox"></span>
                            <a target='_blank' className="paypal header-action-btn icon-social-facebook m-0" title="Facebook" href="https://www.facebook.com/astrovishalsaxena"></a>
                            <a target='_blank' className="twitter  header-action-btn icon-social-twitter" title="Twitter" href="https://twitter.com/astrovsaxena"></a>
                            <a target='_blank' className="tumblr icon-social-instagram" title="Instagram" href="https://www.instagram.com/astrovishal/"></a>
                            <a target='_blank' className="tumblr icon-social-youtube" title="Youtube" href="https://www.youtube.com/@astrosaxena"></a>
                            <a target='_blank' className="tumblr icon-social-linkedin" title="LinkedIn" href="https://www.linkedin.com/in/vishal-saxena-b01205238/"></a>
                            
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>

        <div className="green1 header-bottom border-box-2_22 d-lg-none sticky-nav text-white bg-black">
            <div className="container_top position-relative">
                <div className="row align-self-center">
                    
                    <div className="col-auto align-self-center">
                        <div className="header-logo">
                            <a href="/"><img src={logoImg} alt="Site Logo" /></a>
                        </div>
                    </div>
                    <div className="col-auto align-self-center">
                        <div className="header-logo">
Swami Premanad Bharti
                        </div>
                    </div>
                    
        
                    
                    <div className="col align-self-center ">
                        <div className="header-actions">
                            <div className="header_account_list">
                                <a href="javascript:void(0)" className="text-white header-action-btn search-btn"><i
                                        className="icon-magnifier"></i></a>
                                <div className="dropdown_search">
                                    <form className="action-form" action="#">
                                        <input className="form-control" placeholder="Enter your search key" type="text" />
                                        <button className="submit" type="submit"><i className="icon-magnifier"></i></button>
                                    </form>
                                </div>
                            </div>
                            
                            <div id="user_opt_mob" className="header-bottom-set dropdown">
                            </div>
                            
                           
                            <a href="#offcanvas-mobile-menu" className="text-white1 header-action-btn header-action-btn-menu offcanvas-toggle d-lg-none">
                                <i className="icon-menu"></i>
                            </a>
                        </div>
                    </div>
                    
                </div>

{/* second row for top header */}
<div className="row align-self-center">
                    {/* <div className="col-auto align-self-center">
                        <div className="header-logo">
                         <span id="clockbox_mob"></span>
                        </div>
                    </div> */}
                    
        
                    
                    <div className="col align-self-center ">
                    <a className="txt_white align_center smFonts" href="tel:+917207658536"><i class="fa fa-phone"></i>+91 7207658536 (Call Timing - 11 am IST to 8 PM IST)</a>
                    </div>
                    
                </div>
          

            </div>
        </div>
        
        <div className="green d-none d-lg-block sticky-nav">
            <div className="container_top position-relative">
                <div className="row">
                    
                    <div className="col-md-12 align-self-center">
                        <div className="main-menu manu-color-white">
                            <ul id="menu_opt">
                            </ul>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
        
        </div>
        <div id="offcanvas-mobile-menu" className="offcanvas offcanvas-mobile-menu">
        <button className="offcanvas-close"></button>
        <div className="inner customScroll">
        
            <div className="offcanvas-menu mb-4">
                <ul id="mob_menu">
                </ul>
            </div>
        </div>
        </div>
        </div>
        )
    }
}
export default Front_Header;