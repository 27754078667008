import $ from 'jquery';
import React from 'react';
export function Comp_Contact_Submit(){
    return( <div className="ratting-form-wrapper pl-50">
    <h3>Contact Us</h3>
    <div className="ratting-form">
        <form id="frmSingCommentForm">
        <input type="hidden" name="curl_type" id="curl_type" value="submitContactForm" />  
<input type="hidden" name="m_type" id="m_type" value="submitContactForm" />
<input type="hidden" name="res_func" id="res_func" value="callBackContactFrm" />

<input type="hidden" name="gb_token" id="gb_token" value="5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg" />
<input type="hidden" name="rating" id="rating" value="5"/>
<input type="hidden" name="pg_id" id="pg_id" value="0"/>
<input type="hidden" name="component_id" id="component_id" value="0"/>
            {/* <div className="star-box">
                <span>Your rating:</span>
                <div className="rating-product">
                        <i className="rating__star ion-android-star-outline"></i>
                        <i className="rating__star ion-android-star-outline"></i>
                        <i className="rating__star ion-android-star-outline"></i>
                        <i className="rating__star ion-android-star-outline"></i>
                        <i className="rating__star ion-android-star-outline"></i>
                   
                </div>
            </div> */}
            <div className="row">
                <div className="col-md-6">
                    <div className="rating-form-style">
                        <input className="required" placeholder="Name" type="text" id="name" name="name" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="rating-form-style">
                        <input placeholder="Email" type="text" id="email" name="email" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="rating-form-style">
                        <input className="required" placeholder="Phone Number" type="text" id="phone" name="phone" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="rating-form-style">
                        <input className="required" placeholder="Subject" type="text" id="subject" name="subject" />
                    </div>
                </div>
                {/* <div className="col-md-6">
                    <div className="rating-form-style">
                        <input placeholder="Contact No" id="contact_no" name="contact_no" type="text" />
                    </div>
                </div> */}
                <div className="col-md-12">
                    <div className="rating-form-style form-submit">
                        <textarea className="required" name="comment" id="comment" placeholder="Type your message"></textarea>
                        <button className="btn btn-primary btn-hover-color-primary _btnSetColor" type="button" value="Submit">Submit</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>)
}
export default Comp_Contact_Submit;