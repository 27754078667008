import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import './icon_title.css';
import './text_thumbnail.css';
// component ref from ArticleListing
export function Text_Thumbnail(lsData){

  //return JSON.stringify(lsData);
  var appUrtPath=appGetUrlPath();
  var listData=lsData;

  let strBgImg="";
  let title="";
  if(lsData.length>0){
    strBgImg=lsData[0]["bg_img"];
    title=lsData[0]["g_title"];
   
    if(strBgImg!==""){
        strBgImg=appUrtPath+lsData[0]["bg_img"];
      strBgImg="style=\"background-size:cover;background-image:url("+strBgImg+")\"";
    }
  }
  //let isWhite="txt-white";
  let isWhite="";

  /* custome Conditions for data */
  let customDesc="";
 


  
      var strResp="";
      let hrefLink="";
      let bgAssign="";
      let vContainerType="container";

      let colDef="col-lg-6 col-md-6 col-sm-6 col-xs-6";

    for(var i=0;i<listData.length;i++){

      if(listData[i]["col11"]!==""){
        vContainerType=listData[i]["col11"];
      }

      if(listData[i]["col10"]===""){
        hrefLink="blog\\"+urlTitle+"?rf="+lsData[i]["e_id"];
        if(listData[i]["col7"]!==""){
         hrefLink=listData[i]["col7"];
        }
        hrefLink="javascript:void(0)";
   
       var imgPath="default-product.png"; 
       var urlTitle= lsData[i]["col1"];
       urlTitle=urlTitle.replace("?","_");
       urlTitle=urlTitle.replace(" ","-");

       let strImgPaths="";
      
       
         if(listData[i]["col3"].trim()===""){
         
          if(listData[i]["col4"]!=""){
            imgPath=listData[i]["col4"];
            
            if(listData[i]["col4"].indexOf(".mp4")!==-1){
              strImgPaths="<video><source src=\""+appUrtPath+imgPath+"\" type=\"video/mp4\"></video>";
            }else{
              strImgPaths="<img class=\"flex-column_thumb\"  src=\""+appUrtPath+imgPath+"\" />";
            }
             }
         
          if(listData[i]["col13"].trim()!==""){
            colDef=listData[i]["col13"].trim();
            
          }
             //alert();   

          strResp+="<div class=\""+colDef+"\">"+
          "<div class=\"border-box-2 border-box-1 product12 mb-5\">"+
          "<form>"+
              "<div class=\"content pd_lr-10px\">"+
                 "<div class=\"flex-body_thumb pt-2 pb-2\">"+
                 strImgPaths+
                  "<h6 style=\"float:left\"><a class=\"f_color_1 flex-column_thumb\" href=\""+hrefLink+"\">"+listData[i]["col2"]+"</a></h6>"+
                  "<span class=\"price1 heading article_thumb flex-row_thumb\">"+
                  lsData[i]["col3"]+
               "</span>"+
                  "</div>"+
                 
              "</div>"+
          "</form>"+"</div>"+
      "</div>";
         }else{
          if(listData[i]["col4"]!=""){
            imgPath=listData[i]["col4"];
            
            if(listData[i]["col4"].indexOf(".mp4")!==-1){
              strImgPaths="<video><source src=\""+appUrtPath+imgPath+"\" type=\"video/mp4\"></video>";
            }else{
              strImgPaths="<img class=\"\"  src=\""+appUrtPath+imgPath+"\" />";
            }
             }
             if(listData[i]["col13"].trim()!==""){
              colDef=listData[i]["col13"].trim();
              
            }   
          strResp+="<div class=\""+colDef+"\">"+
          "<div class=\"border-box-2 border-box-1 product12 mb-5\">"+
          "<form>"+
              "<div class=\"content pd_lr-10px\">"+
                 "<div class=\"flex-body_thumb flex-column_thumb pt-2 pb-2\">"+
                 strImgPaths+
                  "<h6><a class=\"f_color_1 \" href=\""+hrefLink+"\">"+listData[i]["col2"]+"</a></h6>"+
                  "</div>"+
                  "<span class=\"price1 heading article_thumb \">"+
                  lsData[i]["col3"]+
               "</span>"+
              "</div>"+
          "</form>"+"</div>"+
      "</div>";
         }
        

     //alert(strResp);
      }else if(listData[i]["col10"]==="top_text"){
        title=listData[i]["col1"];
        customDesc=listData[i]["col2"];
        bgAssign=listData[i]["col12"];
        if(listData[i]["col11"]!==""){
          vContainerType=listData[i]["col11"];
        }
      }
      
    }

    //alert(vContainerType);
    var strHeader="<div "+strBgImg+" class=\"shop-category-area "+bgAssign+" pb-100px pt-70px\">"+
    "<div class=\""+vContainerType+"\">"+
    "<div class=\"row\">"+
      "<div class=\"col-md-12\">"+
          "<div class=\"heading page__title-bar1\">"+
              "<center><h3 class=\"title "+isWhite+"\">"+title+"</h3></center>"+
          "</div>"+
      "</div>"+
      "<div class=\"col-md-12 mb-5\">"+
      "<div class=\"heading page__title-bar1 "+isWhite+" \">"+
          customDesc+
      "</div>"+
  "</div>"+
  "</div>"+
        "<div class=\"row\">"+
            "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
            "<div class=\"shop-bottom-area\">"+
            "<div class=\"row\">";
     var strFooter="</div></div></div></div></div></div>";


    return strHeader+strResp+strFooter;
}
export default Text_Thumbnail;
