import  $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,RespBkMthdMapp,AjaxCallMethod,GetImgCertUser} from '../function_lib/common_lib';
import {CallCMSMapMethod} from '../function_lib/common_cms';
import Front_Header from './front_header';
import Footer from './footer';
import Cart_Canvas from '../sample_comp/cart_canvas';
import Product_Listing from '../sample_comp/product_listing';
import AOS from 'aos';
/* components */
import Top_Banner from '../sample_comp/top_banner';
import Features_Slider  from '../sample_comp/features_slider';
import Home_Banner from '../sample_comp/home_banner';
import Article_Blogs from '../sample_comp/article_blogs';
import Left_Img_Txt_Desc from '../sample_comp/left_img_txt_desc';
import Single_Img_Msg from '../sample_comp/single_img_msg';
import Txt_Description from '../sample_comp/txt_description';
import Footer_Register from '../sample_comp/footer_register';
import './contact_us.css';

let loginUser="";
let userObject="";
let clsObject="";
let loc="";
let products=""
class Contact_Us extends React.Component{
    constructor(props){
        
        super(props);
        loc=props.location["pathname"].substr(1);
        if(localStorage.getItem("userData")!=null){
          //alert(localStorage.getItem("userData"));
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          if(userObject){
            //loginUser=userObject[0].person_name;
          }
          
          //window.location.href="./Dashboard";
        }
       
      }
    componentDidMount(){
        clsObject=this;
  var mapCat=[];
  mapCat.push({name:"curl_type",value:"getProducts"});
  mapCat.push({name:"res_func",value:"callBackProList"});
  mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
  mapCat.push({name:"p1",value:"0"});
  mapCat.push({name:"p2",value:"0"});
 // mapCat.push({name:"emp_id",value:userObject[0].id});
  mapCat["res_func"]="callBackProList";
  mapCat["curl_type"]="getProducts";
  CallMapMethod(clsObject,mapCat);
//   alert("i m in contact");
    }
    callBackpgData(data,msg){
       // alert("testing of2 "+data);
        //$("#pgComp").html(data);
        var listObj= JSON.parse("{\"access\":"+data+"}");
        //alert("successfully parse");
        var mainHt="";
        if(loc==="home"){
           // mainHt+=Video_Banner_Comp();
        }
        var injextI=0;
        var injCondition=false;
      
        for(var _dlist=0;_dlist<50;_dlist++){
         
            if(listObj.access[_dlist]){
                if(listObj.access[_dlist].top_banner){
                   // alert("I am in conditio");
                   injCondition=true;
                   mainHt+=Top_Banner(listObj.access[_dlist].top_banner);
                }
                else if(listObj.access[_dlist].home_banner){
                   // alert("i am in cond..");
                   injCondition=true;
                    mainHt+=Home_Banner(listObj.access[_dlist].home_banner);
                }else if(listObj.access[_dlist].dual_text_card){
                    injCondition=true;
                    //var dbs=render(){return(new Dual_Banner(listObj.access[_dlist].dual_text_card)};
                   // mainHt+=Dual_Banner(listObj.access[_dlist].dual_text_card);
                }else if(listObj.access[_dlist].single_img_text){
                    injCondition=true;
                    mainHt+=Single_Img_Msg(listObj.access[_dlist].single_img_text);
                }else if(listObj.access[_dlist].img_text_desc_img_left){
                    injCondition=true;
                    mainHt+=Left_Img_Txt_Desc(listObj.access[_dlist].img_text_desc_img_left);
                }else if(listObj.access[_dlist].text_description){
                    injCondition=true;
                    //alert("i am in text");
                    //alert(Txt_Description(listObj.access[_dlist].text_description));
                    mainHt+=Txt_Description(listObj.access[_dlist].text_description);
                }else if(listObj.access[_dlist].list_slider){
                    injCondition=true;
                    mainHt+=Article_Blogs(listObj.access[_dlist].list_slider);
                }else if(listObj.access[_dlist].top_info_slider){
                    injCondition=true;
                    //alert("i am in topindo");
                    $("#top_msg_bar").html(listObj.access[_dlist].top_info_slider[0]["col2"]);
             }
                if(injCondition){
                    injextI++;
                }
                //if(injextI==1)
                {
                    //alert("check in condi == "+loc)
                    //if(loc==="home"){
                       // mainHt+=Features_Slider();
                        //alert(products);
                       // mainHt+=products;
                   // }
               }
            }
    
          
            //$("#pgComp").innerHtml=mainHt;
        }
        if(listObj.access.length==0){
           // mainHt+=products;
        }
        //alert(mainHt);
        //mainHt+=Product_Hori_Slider();
        $("#pgComp").html(mainHt);
        RespBkMthdMapp(clsObject,"_addToCart","setCartBk");
        AOS.init();
        AOS.init({
          once: true
        });
        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
      
        document.body.appendChild(script);
          //this.forceUpdateHandler();
      
    }
    setCartBk(refId){
        // alert(refId);
         this.setState(state =>({viewData:refId}));
         this.forceUpdate();
         $("#offcanvas-cart").addClass("offcanvas-open");
       }
    callBackProList(data,msg){
    //alert("testing=="+data);
      if(data!=="0"){
       //var resPs= Product_Slider(data);
       var resPs=Product_Listing(data);
       //alert(resPs);
       products+=resPs;
      //$("#pgComp").append(resPs);
      }
      var pgMap=[];
      pgMap.push({name:"curl_type",value:"getPgData"});
      pgMap.push({name:"res_func",value:"callBackpgData"});
      pgMap.push({name:"loc",value:loc});
     // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
      //mapCat.push({name:"emp_id",value:userObject[0].id});
      pgMap["res_func"]="callBackpgData";
      pgMap["curl_type"]="getPgData";
      CallCMSMapMethod(clsObject,pgMap);
    }
    render(){
        return(<div>
             <Front_Header/>
             <div id="pgComp">
           </div>
           
        <div class="container container-fluid px-1 py-5 mx-auto">
    <div class="row d-flex justify-content-center">
        <div class="col-xl-12 col-lg-12 col-md-10 text-center">
            
           
            <div class="card dia_cart border-box-3">
                <h5 class="text-center mb-4"></h5>
                <form id="frmEdu" name="frmEdu" enctype="multipart/form-data" method="POST" action="#" class="form-card" onsubmit="event.preventDefault()">
                <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                        <input type="hidden" name="curl_type" id="curl_type" value="insEducationDetails" />
                        <input type="hidden" name="m_type" id="m_type" value="insEducationDetails" />
                        <input  type="hidden" name="type_id" id="type_id" value="19" />
                        <input type="hidden" name="host_id" id="host_id" value="0"/>
                        <input type="hidden" name="emp_id" id="emp_id" value="0"/>
                        <input type="hidden" name="col6" id="col6" value=""/>
                        <input type="hidden" name="lsImages" id="lsImages" />
                        <div className='row'>
                            <div className='col-md-12 pb-50px align-left'>
                                <div className='float-left'>
                                <h1>Get in Touch !</h1>
                                </div>
                                
                            </div>
                        </div>
                        <div class="row justify-content-between text-left">
                        <div class="form-group col-sm-12 flex-column d-flex"> <label class="form-control-label px-3">Select Purpose of Contact<span class="text-danger"> *</span></label>    
                        <select className="form-control required" id="col3" name="col3">
          <option value="0">-Select-</option>
          <option value="Become Reseller with Us">-Become Reseller with Us-</option>
          <option value="Become Retailer">-Become Retailer-</option>
          <option value="Franchise Partner">-Franchise Partner-</option>
            
            
          </select> </div>
                        <div class="form-group col-sm-6 flex-column d-flex"> <label class="form-control-label px-3">First Name<span class="text-danger"> *</span></label> <input className="form-control form-control-sm required" autoComplete='off'  type="text" id="col1" name="col1" placeholder="First Name" onblur="validate(1)" /> </div>
                        <div class="form-group col-sm-6 flex-column d-flex"> <label class="form-control-label px-3">Last Name<span class="text-danger"> *</span></label> <input className="form-control required" autoComplete='off'  type="text" id="col2" name="col2" placeholder="Last Name" onblur="validate(1)" /> </div>

                       
          
          <div class="form-group col-sm-12 flex-column d-flex"> <label class="form-control-label px-3">Email<span class="text-danger"> *</span></label> <input className="form-control required" autoComplete='off'  type="text" id="col4" name="col4" placeholder="Email Address" onblur="validate(1)" /> </div>
          <div class="form-group col-sm-12 flex-column d-flex"> <label class="form-control-label px-3">Job Title<span class="text-danger"> *</span></label> <input className="form-control required" autoComplete='off'  type="text" id="col5" name="col5" placeholder="Job Title" onblur="validate(1)" /> </div>

          <div class="form-group col-sm-6 flex-column d-flex"> <label class="form-control-label px-3">Phone<span class="text-danger"> *</span></label> <input className="form-control required" autoComplete='off'  type="text" id="col7" name="col7" placeholder="Phone Number" onblur="validate(1)" /> </div>
          <div class="form-group col-sm-6 flex-column d-flex"> <label class="form-control-label px-3">Company / Organization <span class="text-danger"> *</span></label> <input className="form-control required" autoComplete='off'  type="text" id="col6" name="col6" placeholder="Company / Organization" onblur="validate(1)" /> </div>
          <div class="form-group col-sm-6 flex-column d-flex"> <label class="form-control-label px-3">Industry<span class="text-danger"> *</span></label> <input className="form-control required" autoComplete='off'  type="text" id="col8" name="col8" placeholder="Industry" onblur="validate(1)" /> </div>
          <div class="form-group col-sm-6 flex-column d-flex"> <label class="form-control-label px-3">Position / Level<span class="text-danger"> *</span></label> <input className="form-control required" autoComplete='off'  type="text" id="col9" name="col9" placeholder="Position / Level" onblur="validate(1)" /> </div>
          <div class="form-group col-sm-12 flex-column d-flex"> <label class="form-control-label px-3">Country<span class="text-danger"> *</span></label> <input className="form-control required" autoComplete='off'  type="text" id="col10" name="col10" placeholder="Country" onblur="validate(1)" /> </div>
          <div class="form-group col-sm-12 flex-column d-flex"> <label class="form-control-label px-3">Your Message<span class="text-danger"> *</span></label> 
          
          <textarea id="col14" name="col14" className="form-control required">
            </textarea> 
          </div>
          <div className='form-group col-sm-12'>
          <p> <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
<label for="vehicle1">I agree to <b>The Middle Mans</b> collecting and processing my personal data to allow me to receive information on <b>The Middle Mans</b>.Please see <a href="../privacy_policy">Our Privacy Policy</a></label></p>
          </div>
         
        </div>
  

                    <div class="row justify-content-end">
                        <div class="form-group col-sm-6"> <button type="button" onClick={()=>this.submitInv("#frmEdu")} class="btn btn-block btn-secondary">Submit</button> </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
      
<Footer_Register/>
     <Footer/>
        </div>);
    }
}
export default Contact_Us; 