import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import './collapse_pan.css';
export function Collapse_Pan(lsData,isSet=""){
/*Header Values */
var title="";
if(lsData.length!=0){
title=lsData[0]["g_title"];
}

    var appPath=appGetUrlPath();
//var listData=JSON.parse(lsData);
var strResp="";


var strHeader="<div id=\"faq\" class=\"panel-group border-box\"><div class=\"container\"><div class=\"row\">";
/*
var searchPan="<div class=\"row ml-auto\">"+
"<div class=\"col-md-12\">"+
"<form class=\"form-inline ml-3\">"+
"<div class=\"input-group input-group-sm\">"+
"<input style=\"width:80%\" class=\"form-control form-control-navbar\" type=\"search\" placeholder=\"Search\" aria-label=\"Search\" />"+
"<div class=\"input-group-append\">"+
  "<button class=\"btn btn-navbar\" type=\"submit\">"+
    "<i class=\"fa fa-search\"></i>"+
  "</button>"+
"</div>"+
"</div>"+
"</form>"+
"</div>"+
"</div>";
*/
var searchPan="<div class=\"dropdown_search clsSearchCollapse\" style=\"width:60%;margin-bottom:10px\"><form class=\"action-form\" action=\"#\"><input class=\"form-control\" placeholder=\"Enter your search key\" type=\"text\"><button class=\"submit\" type=\"submit\"><i class=\"icon-magnifier\"></i></button></form></div>";
strHeader+=searchPan;
var strFooter="</div></div></div>";

for(var i=0;i<lsData.length;i++){
    
    var clpText="know more";
    if(lsData[i]["col8"]!==""){
        clpText=lsData[i]["col8"];
    }
    var clpUrl="";
    if(lsData[i]["col7"]!==""){
        clpUrl=lsData[i]["col7"];
        clpUrl="<a href="+clpUrl+">"+clpText+"</a>";
    }
    let colPanWidth="col-sm-12 col-md-6 col-xs-6";
    if(isSet==="1"){
        colPanWidth="col-sm-12 col-md-12 col-xs-6"
    }

    var imgIf="";
    if(lsData[i]["col4"]!==""){
        imgIf="<div class=\"thumb collapse_pan\">"+
        "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"blog\" />"+
    "</div>";
    }
    strResp+="<div class=\""+colPanWidth+" panel panel-default no-border single-my-account\">"+
            "<div class=\"panel-heading my-account-title\">"+
        "<h3 class=\"panel-title\"><a data-bs-toggle=\"collapse\" href=\"#my-account-"+lsData[i]["id"]+"\" class=\"collapsed\" aria-expanded=\"false\">"+lsData[i]["col1"]+"</a></h3>"+
    "</div>"+
    "<div id=\"my-account-"+lsData[i]["id"]+"\" class=\"panel-collapse collapse collapsed\" data-bs-parent=\"#faq\">"+
        "<div class=\"panel-body\">"+
        imgIf+lsData[i]["col2"]+clpUrl+
        "</div>"+
    "</div>"+
"</div>";
}
return strHeader+strResp+strFooter;
}
export default Collapse_Pan;