import $ from 'jquery';
import React from 'react';
import { findDOMNode } from "react-dom";
import { useLocation } from 'react-router-dom';
// import routes from "./routes";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';

import Pages from './pages';
import Products from './pages/product_list';
import Product_Landing from './pages/product_landing';
import Step_Two from './pages/step_two';
import Login from './pages/login';
import ContactUs from './pages/contact_us';
//import Blogs from './pages/Blogs_Articles';
import Receipt from './pages/receipt';

//Sample of Component
import AllComp from './sample_comp/all_component';
import Blogs_Page from './pages/blogs_page';
import Blog_Landing from './pages/blog_landing';

import Jobs_Page from './pages/jobs_page';
import Job_Landing from './pages/job_landing';

//Login User Pages
import My_Profile from './user_pages/my_profile';
import Invoice_Receipt from './user_pages/invoice_receipt';
import My_Orders from './user_pages/my_orders';
import Business_Registration from './pages/business_registration';
import Index_Land from './pages/index_land';



class App extends React.Component{
  constructor(props){
    super(props);
    
  }
  componentDidMount() {
    // const script = document.createElement("script");    
    // script.async = true;    
    // script.src = "./assets/js/default/active.js"; 
    // document.body.appendChild(script);   
      }
     
  render(){
    return <Router><Switch>
      <Route exact path="/contact_us" component={ContactUs}/>
      {/* <Route exact path="/contactus" render={props => <ContactUs {...props} />}/> */}
      <Route exact path="/blogs" component={Blogs_Page} />
      <Route exact path="/blog/:name" component={Blog_Landing} />

      <Route exact path="/jobs" component={Jobs_Page} />
      <Route exact path="/job_landing" component={Job_Landing}/>

      <Route exact path="/business_registration" component={Business_Registration} />
      <Route exact path="/franchise" component={Business_Registration} />

      <Route exact path="/seeds" component={()=><Products productId={174} productCategory={"seeds"}  />} />
      <Route exact path="/products" component={Products} />
      <Route exact path="/products/:name" component={Products}/>
      <Route exact path="/shop_all" component={Products} />
      <Route exact path="/shop_all/:name" component={Products}/>
      
      <Route exact path="/product/:name" component={Product_Landing}/>
      <Route exact path="/Login" component={Login} />
      <Route exact path="/steptwo" component={Step_Two}/>
      {/* <Route exact path="/invoice_receipt" component={Receipt} /> */}
      <Route exact path="/invoice_receipt" component={Invoice_Receipt} />
      <Route exact path="/my_profile" component={My_Profile}/>
      <Route exact path="/my_orders" component={My_Orders} />
      <Route exact path="/my_invoices" component={My_Orders} />
      
    <Route exact path="*/*" component={Pages} />
  </Switch></Router>
  }
}
export default App;