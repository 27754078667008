import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import './left_img_txt_desc.css';
//import lefImg1 from '../assets/images/about-image/1.jpg';

export function Text_Image_Full(lsData,isWhite=false,newPath="",needAds=""){

    
let _homeBanner_Ads_136="<div id=\"ezoic-pub-ad-placeholder-136\"> </div>";
let _homeBanner_Ads_135="<div id=\"ezoic-pub-ad-placeholder-135\"> </div>";

    var appPath= appGetUrlPath();
    if(newPath!==""){
        appPath=newPath;
    }
    var strResp="";
    let strBtn="";

    let clsWhite="";
    if(isWhite){
        clsWhite="text-white";
    }
    //alert(JSON.stringify(lsData));
        for(var i=0;i<lsData.length;i++){
            let isBackGroundImg="";
            strBtn="";
            if(lsData[i]["col7"].trim()!==""){
                strBtn="<a type=\"button\" class=\"btn text-center btn-primary btn-hover-primary ml-4\" href=\""+lsData[i]["col7"]+"\">"+lsData[i]["col8"]+"</a>";
            }
            if(lsData[i]["col5"].trim()!==""){
                isBackGroundImg="style=\"min-height:400px;background-size:cover;background-image:url("+appPath+lsData[i]["col5"].trim()+")\"";
            }

          let  imgPath=lsData[i]["col4"];
        let strImgPaths="";
        if(lsData[i]["col4"].indexOf(".mp4")!==-1){
          strImgPaths="<center><video id=\"vid_home\" controls><source src=\""+appPath+imgPath+"\" type=\"video/mp4\"></video></center>";
        }else{
          strImgPaths="<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\" img-responsive w-100\" />";
        }
        let bgAssign=lsData[i]["col12"];
         
            
                strResp+="<section  "+isBackGroundImg+" class=\"left_img_txt_desc "+bgAssign+" border-box about-area pb-70px pt-70px\">"+
            "<div class=\"container\">"+
                "<div class=\"row align-items-center\">"+
                   
                    "<div data-aos=\"fade-up\" data-aos-duration=\"2000\" class=\"col-lg-12\">"+
                        "<div class=\"about-content\">"+
                        "<div class=\"col-lg-12\">"+
                        "<div class=\"about-left-image mb-md-30px mb-lm-30px\">"+
                        strImgPaths+
                        _homeBanner_Ads_136+
                        "</div>"+
                    "</div>"+
                            "<div class=\"about-title\">"+
                                "<h3>"+lsData[i]["col1"]+"</h3>"+
                            "</div>"+
                            "<p  data-aos=\"fade-up\" class=\"mb-6\">"+
                               lsData[i]["col2"]+_homeBanner_Ads_135+
                            "</p>"+
                            "<p class=\"mb-6\">"+
                            lsData[i]["col3"]+
                         "</p>"+
                            "<p>"+
                                strBtn+
                            "</p>"+
                        "</div>"+
                   "</div>"+
                  
                "</div>"+
            "</div>"+
            "</section>"
            
            
        }
        return strResp;
    

}

export function Left_Txt_Txt_Desc(lsData){
    var appPath= appGetUrlPath();
    var strResp="";
    let strBtn="";
   
        for(var i=0;i<lsData.length;i++){
            let isBackGroundImg="";
            strBtn="";
            if(lsData[i]["col7"].trim()!==""){
                strBtn="<a type=\"button\" class=\"btn text-center btn-primary btn-hover-primary ml-4\" href=\""+lsData[i]["col7"]+"\">"+lsData[i]["col8"]+"</a>";
            }
            if(lsData[i]["col5"].trim()!==""){
                isBackGroundImg="style=\"min-height:400px;background-size:cover;background-image:url("+appPath+lsData[i]["col5"].trim()+")\"";
            }
            let bgAssign=lsData[i]["col12"];
    //alert(lsData[i]["col7"].trim());
            if(i%2==0){
                strResp+="<section "+isBackGroundImg+" class=\"left_img_txt_desc "+bgAssign+" border-box1 about-area pb-70px pt-70px\">"+
                "<div class=\"container\">"+
                    "<div class=\"row align-items-center\">"+
                        "<div class=\"col-lg-6\">"+
                        "<div class=\"about-content\">"+
                       
                        "<p class=\"mb-6\">"+
                           lsData[i]["col2"]+
                        "</p>"+
                       
                    "</div>"+
                        "</div>"+
                        "<div class=\"col-lg-6\">"+
                            "<div class=\"about-content\">"+
                               
                                "<p class=\"mb-6\">"+
                                   lsData[i]["col3"]+
                                "</p>"+
                               
                            "</div>"+
                       "</div>"+
                    "</div>"+
                "</div>"+
                "</section>"
            }else{
                strResp+="<section class=\"left_img_txt_desc "+bgAssign+" border-box about-area pb-70px pt-70px\">"+
            "<div class=\"container\">"+
                "<div class=\"row align-items-center\">"+
                   
                    "<div class=\"col-lg-6\">"+
                        "<div class=\"about-content\">"+
                            "<div class=\"about-title\">"+
                                "<h3>"+lsData[i]["col1"]+"</h3>"+
                            "</div>"+
                            "<p class=\"mb-6\">"+
                               lsData[i]["col2"]+
                            "</p>"+
                            "<p>"+
                                strBtn+
                            "</p>"+
                        "</div>"+
                   "</div>"+
                   "<div class=\"col-lg-6\">"+
                   "<div class=\"about-left-image mb-md-30px mb-lm-30px\">"+
                       "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\" img-responsive w-100\" />"+
                   "</div>"+
               "</div>"+
                "</div>"+
            "</div>"+
            "</section>"
            }
            
        }
        return strResp;
}

export function Tab_Data_LIT(lsData){
    var appPath= appGetUrlPath();
    let w_100="";
    let strBtn="";
    let clsWhite="";

    

   let strHeader="";
    for(let h=0;h<lsData.length;h++){
        if(h===0){
            let bgAssign=lsData[h]["col12"];
            let isBackGroundImg="";
     strHeader="<section "+isBackGroundImg+" class=\"left_img_txt_desc "+bgAssign+" border-box1 about-area pb-70px pt-70px\">";
    strHeader+="<div class=\"container\">";
    strHeader+="<div class=\"sidebar-widget-tag d-inline-block pb-100px\">";
    strHeader+="<ul class=\"nav nav-tabs11\" id=\"custom-tabs-three-tab\" role=\"tablist\">";

            strHeader+="<li class=\"nav-item\">"+
            "<a class=\"nav-link active\" id=\"ct"+h+"\" data-toggle=\"pill\" href=\"#dt"+h+"\" role=\"tab\" aria-controls=\"custom-tabs-three-home\" aria-selected=\"true\">"+lsData[h]["col1"]+"</a>"+
"</li>";
        }else{
            strHeader+="<li class=\"nav-item\">"+
            "<a class=\"nav-link\" id=\"ct"+h+"\" data-toggle=\"pill\" href=\"#dt"+h+"\" role=\"tab\" aria-controls=\"custom-tabs-three-home\" aria-selected=\"true\">"+lsData[h]["col1"]+"</a>"+
"</li>";
        }
       
    }
    strHeader+="</ul></div>";

    let strFooter="</div></section>"

    let strResp="<div class=\"tab-content\" id=\"custom-tabs-three-tabContent\">";
    for(let i=0;i<lsData.length;i++){
        let isActivePan="";
        if(i===0){
            isActivePan="active show"
        }
        strResp+="<div id=\"dt"+i+"\" class=\"row tab-pane "+isActivePan+" fade align-items-center\">"+
        "<div class=\"row\">"+
       
        "<div class=\"col-lg-6\">"+
            "<div class=\""+clsWhite+" about-content\">"+
                "<div class=\"about-title\">"+
                    "<h3 class=\""+clsWhite+"\">"+lsData[i]["col1"]+"</h3>"+
                "</div>"+
                "<p class=\""+clsWhite+" mb-6\">"+
                   lsData[i]["col2"]+
                "</p>"+
                "<p class=\""+clsWhite+"\">"+
                    strBtn+
                "</p>"+
            "</div>"+
       "</div>"+
       "<div class=\"col-lg-6\">"+
       "<div class=\"about-left-image mb-md-30px mb-lm-30px\" >"+
           "<img style=\"margin-left:auto\" src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\"img-responsive "+w_100+"\" />"+
       "</div>"+
   "</div>"+
    "</div></div>";
    }
    strResp+="</div>";
return strHeader+strResp+strFooter;
}

export function Left_Img_Txt_Desc(lsData,isRight=false,isWhite=false,w_100="w-100"){
    
    w_100="";
    // remove above if img ratio not workin
    
    var appPath= appGetUrlPath();
    var strResp="";
    let strBtn="";

    let clsWhite="";
    if(isWhite){
        clsWhite="text-white";
    }

    let strHeader="";
    if(lsData.length>0){
        if(lsData[0]["col10"]==="tab"){
        return Tab_Data_LIT(lsData);
        }
    }


    if(!isRight){
        for(var i=0;i<lsData.length;i++){
            let isBackGroundImg="";
            strBtn="";
            if(lsData[i]["col7"].trim()!==""){
                strBtn="<a type=\"button\" class=\"btn text-center btn-primary btn-hover-primary ml-4\" href=\""+lsData[i]["col7"]+"\">"+lsData[i]["col8"]+"</a>";
            }
            if(lsData[i]["col5"].trim()!==""){
                isBackGroundImg="style=\"min-height:400px;background-size:cover;background-image:url("+appPath+lsData[i]["col5"].trim()+")\"";
            }
            let bgAssign=lsData[i]["col12"];
    //alert(lsData[i]["col7"].trim());
            if(i%2==0){
                strResp+="<section "+isBackGroundImg+" class=\"left_img_txt_desc "+bgAssign+" border-box1 about-area pb-70px pt-70px\">"+
                "<div class=\"container\">"+
                    "<div class=\"row align-items-center\">"+
                        "<div class=\"col-lg-6\">"+
                            "<div class=\"about-left-image mb-md-30px mb-lm-30px\" >"+
                                "<img style=\"margin-left:auto\" src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\"img-responsive "+w_100+"\" />"+
                            "</div>"+
                        "</div>"+
                        "<div class=\"col-lg-6\">"+
                            "<div class=\""+clsWhite+" about-content\">"+
                                "<div class=\"about-title\">"+
                                    "<h3 class=\""+clsWhite+"\">"+lsData[i]["col1"]+"</h3>"+
                                "</div>"+
                                "<p class=\""+clsWhite+" mb-6\">"+
                                   lsData[i]["col2"]+
                                "</p>"+
                                "<p class=\""+clsWhite+"\">"+
                                    strBtn+
                                "</p>"+
                            "</div>"+
                       "</div>"+
                    "</div>"+
                "</div>"+
                "</section>"
            }else{
                strResp+="<section class=\"left_img_txt_desc "+bgAssign+" border-box about-area pb-70px pt-70px\">"+
            "<div class=\"container\">"+
                "<div class=\"row align-items-center\">"+
                   
                    "<div class=\"col-lg-6\">"+
                        "<div class=\"about-content\">"+
                            "<div class=\"about-title\">"+
                                "<h3>"+lsData[i]["col1"]+"</h3>"+
                            "</div>"+
                            "<p class=\"mb-6\">"+
                               lsData[i]["col2"]+
                            "</p>"+
                            "<p>"+
                                strBtn+
                            "</p>"+
                        "</div>"+
                   "</div>"+
                   "<div class=\"col-lg-6\">"+
                   "<div class=\"about-left-image mb-md-30px mb-lm-30px\">"+
                       "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\" img-responsive "+w_100+"\" />"+
                   "</div>"+
               "</div>"+
                "</div>"+
            "</div>"+
            "</section>"
            }
            
        }
        return strHeader+strResp;
    }else{
        for(var i=0;i<lsData.length;i++){
            let isBackGroundImg="";
            strBtn="";
            if(lsData[i]["col5"].trim()!==""){
                isBackGroundImg="style=\"background-repeat:no-repeat;background-size:cover;background-image:url("+appPath+lsData[i]["col5"].trim()+")\"";
            }
            if(lsData[i]["col7"].trim()!==""){
                strBtn="<a type=\"button\" class=\"btn text-center btn-primary btn-hover-primary ml-4\" href=\""+lsData[i]["col7"]+"\">"+lsData[i]["col8"]+"</a>";
            }
            let bgAssign=lsData[i]["col12"];
    //alert(lsData[i]["col7"].trim());
            if(i%2==0){
                //alert(isBackGroundImg);
                strResp+="<section "+isBackGroundImg+" class=\"left_img_txt_desc "+bgAssign+" border-box1 about-area pb-70px pt-70px\">"+
                "<div class=\"container\">"+
                    "<div class=\"row align-items-center\">"+
                    "<div class=\"col-lg-6\">"+
                    "<div class=\"about-content\">"+
                        "<div class=\"about-title\">"+
                            "<h3>"+lsData[i]["col1"]+"</h3>"+
                        "</div>"+
                        "<p class=\"mb-6 "+clsWhite+"\">"+
                           lsData[i]["col2"]+
                        "</p>"+
                        "<p>"+
                            strBtn+
                        "</p>"+
                    "</div>"+
               "</div>"+
                        "<div class=\"col-lg-6\">"+
                            "<div class=\"about-left-image mb-md-30px mb-lm-30px\" >"+
                                "<img  src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\" img-responsive w-100\" />"+
                            "</div>"+
                        "</div>"+
                       
                    "</div>"+
                "</div>"+
                "</section>"
            }else{
                strResp+="<section class=\"left_img_txt_desc "+bgAssign+" border-box about-area pb-70px pt-70px\">"+
            "<div class=\"container\">"+
                "<div class=\"row align-items-center\">"+
                   
                    "<div class=\"col-lg-6\">"+
                        "<div class=\"about-content\">"+
                            "<div class=\"about-title\">"+
                                "<h3>"+lsData[i]["col1"]+"</h3>"+
                            "</div>"+
                            "<p class=\"mb-6\">"+
                               lsData[i]["col2"]+
                            "</p>"+
                            "<p>"+
                                strBtn+
                            "</p>"+
                        "</div>"+
                   "</div>"+
                   "<div class=\"col-lg-6\">"+
                   "<div class=\"about-left-image mb-md-30px mb-lm-30px\">"+
                       "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\" img-responsive w-100\" />"+
                   "</div>"+
               "</div>"+
                "</div>"+
            "</div>"+
            "</section>"
            }
        }
        return strHeader+strResp;
    }
}



export default Left_Img_Txt_Desc;