import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
export function Footer_Options(lsData){
    let strHeader="<div class=\"border-box txt-white bg-strip2 privacy_policy_main_area p12b-70px pt-70px\">"+
                   "<div class=\"container\">"+
                   "<div class=\"container-inner\">"+
                  "<div class=\"row\">";
    let strFooter="</div></div></div></div>";
    let strResp="";
    
    let strCol_="";
    if(lsData.length===1){
        strCol_="col-md-12";
    }else if(lsData.length===2){
        strCol_="col-md-6";
    }else if(lsData.length===3){
        strCol_="col-md-4";
    }

    for(let i=0;i<lsData.length;i++){
        
        strResp+="<div class='"+strCol_+" text-center'>"+
        "<a href=\""+lsData[i]["col7"]+"\" class=\"add-cart btn btn-primary btn-hover-primary ml-4\">"+lsData[i]["col1"]+"</a>"+
        "</div>"
    }
 return strHeader+strResp+strFooter;   

}