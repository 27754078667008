import $ from 'jquery';
import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import {SetDate} from '../function_lib/common_cms';
 import './article_blogs.css';
export function Article_Blogs(lsData,isSet="1"){
    //alert(JSON.stringify(lsData));
/*Header Values */
var title="";
if(lsData.length!=0){
title=lsData[0]["g_title"];
}
var appPath=appGetUrlPath();
//var listData=JSON.parse(lsData);
var styleSet="";
//let isWhite="txt-white";
let isWhite="";
if(isSet==="1"){
   // isWhite="";
styleSet="<style>"+".main-blog-area .page__title-bar{display: flex;"+
    "align-items: center;"+
    "justify-content: center;}.blog-image img{"+
        "max-width: 100%;"+
        "height: auto;"+
    "}"+
    ".main-blog-area{"+
       // "background-color: #e2dedda3;"+
        // "margin-bottom: 20px;"+
    "}"+"</style>"
}

var strResp="";

var strHeader=styleSet+"<div  class=\"main-blog-area main-blog-area-gb pt-10 pb-10\">"+
"<div class=\"container\">"+
    "<div class=\"row\">"+
        "<div class=\"col-md-12\">"+
            "<div class=\"heading page__title-bar\">"+
                "<h3 class=\"title "+isWhite+"\">"+title+"</h3>"+
            "</div>"+
        "</div>"+
    "</div>"+
    "<div class=\"new-product-slider swiper-container slider-nav-style-1\">"+
    "<div class=\"new-product-wrapper swiper-wrapper\">";

var strFooter="</div>"+
"<div class=\"swiper-buttons\">"+
"<div class=\"swiper-button-next\"></div>"+
"<div class=\"swiper-button-prev\"></div>"+
"</div>"+
"</div></div></div>";
for(var i=0;i<lsData.length;i++){
    var urlTitle= lsData[i]["col1"];
    urlTitle=urlTitle.replace("?","_");
    urlTitle=urlTitle.replace(" ","-");
    
    let shortDesc="";
    let clpUrl="..\\blog\\"+urlTitle+"?rf="+lsData[i]["e_id"];
    let clpText="";
    if(isSet==="1"){
        shortDesc="<p class=\"blog-detail-text\">"+lsData[i]["col2"]+"</p>";
        clpText="know more";
        if(lsData[i]["col8"]!==""){
            clpText=lsData[i]["col8"];
        }
        if(lsData[i]["col7"]!==""){
            clpUrl="../"+lsData[i]["col7"];
            clpText="<a target=\"_blank\" href="+clpUrl+">"+clpText+"</a>";
        }
    }

   
    

    strResp+="<div class=\"single-blog swiper-slide\">"+
    "<div class=\"blog-image\">"+
        "<a target=\"blank\" href=\""+clpUrl+"\"><img src=\""+appPath+lsData[i]["col4"]+"\" class=\"img-responsive w-100\" alt=\"\"/></a>"+
    "</div>"+
    "<div class=\"blog-text\">"+
        "<h6 class=\"title\"><a target=\"blank\" class=\"blog-heading-link\" href=\""+clpUrl+"\">"+lsData[i]["col1"]+"</a></h6>"+
        shortDesc+
        clpText+
    "</div>"+
"</div>";
}
return strHeader+strResp+strFooter;
}
export default Article_Blogs;