import $ from 'jquery';
import React from 'react';
import './comment_list.css';
export function Comment_List(lsData){
    //alert(lsData.length);
return(<div>
    {lsData.length!==0?<h3 style={{marginTop:"10px"}} className="comment-title">{lsData.length} Comments on this post</h3>:''}

<br></br>
<ul class="comment-list">
    {lsData.map((item,index)=>{
        return(<li class="comment-item">
        <div class="row">
            <div class="col-md-12">
                <div class="comment-metadata">
                    <h4>{item.name}</h4>
                    <p>{item.post_time}</p>
                </div>
                <div class="comment-content">
                    <p>{item.comment}</p>
                </div>
            </div>
        </div>
    </li>)
    })}
    </ul>
</div>);
}
export default Comment_List;