import $ from 'jquery';
import React from 'react';
import Cart_Canvas from '../sample_comp/cart_canvas';
import PayImg from '../assets/images/icons/payment.png';

import amazon from '../assets/images/footer/amazonpay.png';
import bhim from '../assets/images/footer/fbhim.png';
import gpay from '../assets/images/footer/gpay.png';
import mastercard from '../assets/images/footer/mastercard.png';
import rupay from '../assets/images/footer/rupay.png';
import visa from '../assets/images/footer/visa.png';
import logoImg from '../assets/images/logo/logo.png';
import './footer.css';
let cartItems;
class Footer extends React.Component{
    componentDidMount(){
        cartItems=localStorage.getItem("_cart");
        
        var strList="";
          let finalAmt=0;
        if(cartItems!=null && cartItems!==""){
          cartItems=JSON.parse(cartItems);
          //alert("i am in cart item "+cartItems.length);
          $("._cart_elm_count").html(cartItems.length);
          $("._cart_elm_sun").html("₹"+window.cAmount); 
        }
      }
    render(){
        return(
        <div className="pt-100122px">
        <div className="footer-area">
        <div className="footer-container">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                    <div className="col-md-6 col-lg-2 col-sm-6 mb-lm-30px">
                            <div className="single-wedge">
                                <h4 className="footer-herading"><b><a href="javascript:void(0)">Visitor Statistics</a></b></h4>
                               
                                <div className="footer-links">
                                    <div className="footer-row">
                                        <ul className="align-items-center">
                                           <li className="li"><a className="single-link" id="online_counter" href="javascript:void(0)">Online</a></li>
                                            <li className="li"><a className="single-link" id="today_counter" href="javascript:void(0)">Today</a></li>
                                            <li className="li"><a className="single-link" id="yesterday_counter" href="javascript:void(0)">Yesterday</a></li>
                                            <li className="li"><a className="single-link" id="week_counter" href="javascript:void(0)">Week</a></li>
                                            <li className="li"><a className="single-link" id="total_counter" href="javascript:void(0)">Total</a></li>
                                            <li className="li"><a className="single-link" id="record_counter" href="javascript:void(0)">Record</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mb-md-30px mb-lm-30px">
                            <div className="single-wedge">
                               {/* <img className='single_img' src={logoImg} /> */}
                               <h4 className="footer-herading"><b>Contact </b></h4>
                                <p className="about-text1">
                                Email:astrovsaxena@gmail.com<br></br>
                                Contact No: +91 7207658536<br></br>
                                Time to Call:(Timing to call - 11 am IST to 8 PM IST)
                                <br></br><br></br>
                                 <br></br>
                                
                                </p>
                            </div>
                        </div>
                       
                        <div className="col-md-6 col-sm-6 col-lg-2 mb-md-30px mb-lm-30px">
                            <div className="single-wedge">
                                <h4 className="footer-herading"><b>Quick Links</b></h4>
                                <div className="footer-links">
                                    <div className="footer-row">
                                        <ul className="align-items-center">
                                            <li className="li"><a className="single-link" href="../about_me">About Me</a></li>
                                            <li className="li"><a className="single-link" href="../contact_me">Contact Me</a></li>
                                            <li className="li"><a className="single-link" href="../sitemap">List of Article</a></li>
                                            <li className="li"><a className="single-link" href="../houses">Houses</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-2 mb-md-30px mb-lm-30px">
                            <div className="single-wedge">
                                <h4 className="footer-herading"><b>Learn Astrology</b></h4>
                                <div className="footer-links">
                                    <div className="footer-row">
                                        <ul className="align-items-center">
                                            <li className="li"><a className="single-link" href="../Houses">Article on Houses</a></li>
                                            <li className="li"><a className="single-link" href="../Planets">Article on Planets</a></li>
                                            <li className="li"><a className="single-link" href="../Nax">Article on Nakshtras</a></li>
                                            <li className="li"><a className="single-link" href="../Conjunctions">Article on Conjunctions</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-2 mb-md-30px mb-lm-30px">
                            <div className="single-wedge">
                                <h4 className="footer-herading"><b>Learn About You</b></h4>
                                <div className="footer-links">
                                    <div className="footer-row">
                                        <ul className="align-items-center">
                                            <li className="li"><a className="single-link" href="../Ascendants_Lagna">Ascendant Signs</a></li>
                                            <li className="li"><a className="single-link" href="../SunSign">Sun Signs</a></li>
                                            <li className="li"><a className="single-link" href="../MoonSign">Moon Signs</a></li>
                                            <li className="li"><a className="single-link" href="../LordthroughHouses">Lord Through Houses</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                        
                       
                      
                      
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container border-top">
              
                {/* <div className="col-12" style={{paddingTop:"10px",fontSize:"13px"}}>
                        DISCLAIMER: This Site Is Not Intended To Provide Any Type of Advice. Products, Services, Information And Other Content Provided On This Site, Including Information That May Be Provided On This Site Directly Or By Third-Party Vendors Are Provided For Informational Purposes Only. We do not have any other retail website or partner . The Results From The Products May Vary From Person To Person. Images shown here are for representation only, actual product may differ.
                        </div> */}
                        <br></br>
                    <div className="row flex-sm-row-reverse">
                        <div className="col-md-6 text-right">
                            <div className="payment-link">
                            <ul className="link-follow">
                               
                               <li className="li">
                                   <a target='_blank' className="paypal icon-social-facebook m-0" title="Facebook" href="https://www.facebook.com/astrovishalsaxena"></a>
                               </li>
                               <li className="li">
                                   <a target='_blank' className="twitter icon-social-twitter" title="Twitter" href="https://twitter.com/astrovsaxena"></a>
                               </li>
                               <li className="li">
                                   <a target='_blank' className="tumblr icon-social-instagram" title="Instagram" href="https://www.instagram.com/astrovishal/"></a>
                               </li>
                               
                               <li className="li">
                                   <a target='_blank' className="pinterest icon-social-youtube" title="Youtube" href="https://www.youtube.com/@astrosaxena"></a>
                               </li>
                               <li>
                               <a target='_blank' className="tumblr icon-social-linkedin" title="LinkedIn" href="https://www.linkedin.com/in/vishal-saxena-b01205238/"></a>
                               </li>
                               {/* <li className="li">
                                   <a target='_blank' className="icon-social-linkedin" title="Linkedin" href="#"></a>
                               </li>
                               <li className="li">
                                   <a target='_blank' className="icon-social-spotify" title="Spotify" href="#"></a>
                               </li> */}
                              
                           </ul>
                            </div>
                        </div>
                        <div className="col-md-6 text-left">
                            <p className="copy-text">Copyright@2022  AstroSaxena.</p>
                        </div>
                    
                      
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Cart_Canvas/>
        </div>);
    }
}
export default Footer;