import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';

import './article_listing.css';
import './blog_listing.css';


export function Blog_Listing_2(lsData,isblogs=false){
//alert(JSON.stringify(lsData));
  //return JSON.stringify(lsData);

  /* get collarge Combination */
  

  var appUrtPath=appGetUrlPath();
  var listData=sortResults(lsData,"id",false);

  /* custome Conditions for data */
  let customDesc="";


  let strBgImg="";
  let title="";
  if(lsData.length>0){
    strBgImg=appUrtPath+lsData[0]["bg_img"];
    title=lsData[0]["g_title"];
    if(strBgImg!==""){
      strBgImg="style=\"background-image:url("+strBgImg+")\"";
    }
  }
  //let isWhite="txt-white";
  let isWhite="title-black";


  
  if(title=="offerings"){
    //alert("i m in condition")
    customDesc="<p>"+"we cater entire lifecycle of data Journey right for Architecture, engineering to Put it to business "
  } 


  let strBlogsCol="col-lg-3 col-md-4 col-sm-6 col-xs-6";
  var strHeader="";
  if(isblogs){
    strBlogsCol="col-lg-6 col-md-12";
    strHeader="<div "+strBgImg+" class=\"shop-category-area pb-100px pt-10px\">"+
      "<div class=\"container11\">"+
      "<div class=\"row\">"+
        "<div class=\"col-md-12\">"+
            "<div class=\"heading page__title-bar\">"+
                "<h6 class=\" "+isWhite+"\">"+title+"</h6>"+
                "<span style=\"float:right\">for sort</span>"+
            "</div>"+
        "</div>"+
        "<div class=\"col-md-12 mb-5\">"+
        "<div class=\"heading page__title-bar "+isWhite+" \">"+
            customDesc+
        "</div>"+
    "</div>"+
    "</div>"+
          "<div class=\"row\">"+
              "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
              "<div class=\"shop-bottom-area\">"+
              "<div class=\"row\">";
  }else{
    //non blog page
    strHeader="<div "+strBgImg+" class=\"shop-category-area pb-100px pt-70px\">"+
      "<div class=\"container\">"+
      "<div class=\"row\">"+
        "<div class=\"col-md-12\">"+
            "<div class=\"heading align-center page__title-bar\">"+
                "<h3 class=\""+isWhite+"\">"+title+"</h3>"+
            "</div>"+
        "</div>"+
        "<div class=\"col-md-12 mb-5\">"+
        "<div class=\"heading page__title-bar "+isWhite+" \">"+
            customDesc+
        "</div>"+
    "</div>"+
    "</div>"+
          "<div class=\"row\">"+
              "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
              "<div class=\"shop-bottom-area\">"+
              "<div class=\"row\">";
  }

  


       



       var strFooter="</div></div></div></div></div></div>";
  
      var strResp="";
    for(var i=0;i<listData.length;i++){
       
  
      var imgPath="default-product.png"; 
      var urlTitle= lsData[i]["col1"];
      urlTitle=urlTitle.replace("?","_");
      urlTitle=urlTitle.replace(" ","-");
      if(listData[i]["col4"]!=""){
       imgPath=listData[i]["col4"];
        }
        strResp+="<div class=\""+strBlogsCol+"\">"+
        "<div class=\"border-box-2 product mb-5\">"+
        "<form>"+
              "<div class=\"thumb \">"+
                "<a href=\"blog\\"+urlTitle+"?rf="+lsData[i]["e_id"]+"\" class=\"image\">"+
                    "<img src=\""+appUrtPath+imgPath+"\" alt=\"Product\" />"+
                  //   "<img class=\"hover-image\" src=\""+appUrtPath+imgPath+"\" alt=\"Product\" />"+
                "</a>"+
            "</div>"+
            "<div class=\"content\">"+
            "<span class=\"price1 heading article_thumb\">"+
          "<span style=\"float:left;\" class=\"title12 bg_blog_category\">"+lsData[i]["col19"]+"</span>"+
          "<span style=\"float:left;\" class=\"title12 bg_blog_category\">"+lsData[i]["col018"]+"</span>"+
          
       "</span>"+
                "<h6 class=\"title1\"><a class=\"f_color_1\" href=\"blog\\"+urlTitle+"?rf="+lsData[i]["e_id"]+"\">"+listData[i]["col1"]+"</a></h6>"+
                "<span class=\"price1 heading article_thumb\">"+
                lsData[i]["col2"]+
             "</span>"+
             "<span class=\"price1 border-top heading article_thumb\">"+
             "<span style=\"float:left;\" class=\"title1\">"+"Author"+"</span>"+
             "<span style=\"float:right;\" class=\"title1 \">"+listData[i]["f_date"]+"</span>"+
          "</span>"+
            "</div>"+
        "</form>"+"</div>"+
    "</div>";
    }
    return strHeader+strResp+strFooter;
}

export function Blog_Listing(lsData){
  //alert(JSON.stringify(lsData));
  //return JSON.stringify(lsData);
    var appUrtPath=appGetUrlPath();
var listData=sortResults(lsData,"id",false);
//let listData=lsData;
    var strHeader="<div class=\"shop-category-area pb-100px pt-70px\">"+
    "<div class=\"container\">"+
        "<div class=\"row\">"+
            "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
            "<div class=\"shop-bottom-area\">"+
            "<div class=\"row\">";
     var strFooter="</div></div></div></div></div></div>";

    var strResp="";
  for(var i=0;i<listData.length;i++){
     

    var imgPath="default-product.png"; 
    var urlTitle= lsData[i]["col1"];
    urlTitle=urlTitle.replace("?","_");
    urlTitle=urlTitle.replace(" ","-");
    if(listData[i]["col4"]!=""){
     imgPath=listData[i]["col4"];
      }
      strResp+="<div class=\"col-lg-3 col-md-4 col-sm-6 col-xs-6\">"+
      "<div class=\"border-box-2 product mb-5\">"+
      "<form>"+
            "<div class=\"thumb\">"+
              "<a href=\"blog\\"+urlTitle+"?rf="+lsData[i]["e_id"]+"\" class=\"image\">"+
                  "<img src=\""+appUrtPath+imgPath+"\" alt=\"Product\" />"+
                //   "<img class=\"hover-image\" src=\""+appUrtPath+imgPath+"\" alt=\"Product\" />"+
              "</a>"+
          "</div>"+
          "<div class=\"content\">"+
          "<span class=\"price1 heading article_thumb\">"+
          "<span style=\"float:left;\" class=\"title\">"+listData[i]["f_date"]+"</span>"+
          "<span style=\"float:right;\" class=\"title \">"+"live <i class=\"ion-android-favorite-outline\"></i>"+getRandomInt(100)+"</span>"+
       "</span>"+
              "<h5 class=\"title\"><a href=\"blog\\"+urlTitle+"?rf="+lsData[i]["e_id"]+"\">"+listData[i]["col1"]+"</a></h5>"+
          "</div>"+
      "</form>"+"</div>"+
  "</div>";
  }
  return strHeader+strResp+strFooter;

}
function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
export function sortResults(listjs,prop, asc) {
  listjs.sort(function(a, b) {
    
      if (asc) {
          return (parseInt(a[prop]) > parseInt(b[prop])) ? 1 : ((parseInt(a[prop]) < parseInt(b[prop])) ? -1 : 0);
      } else {
          return (parseInt(b[prop]) > parseInt(a[prop])) ? 1 : ((parseInt(b[prop]) < parseInt(a[prop])) ? -1 : 0);
      }
  });
  return listjs;
}
export default Blog_Listing;