import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import './image_grid.css';
export function Image_Grid(lsData){
    var appPath= appGetUrlPath();
    var strResp="";
   
    let strHeader="<div class=\"container pb-70px\">"+
"<div class=\"container-inner\" style=\"max-width:100%\">"+
    "<div class=\"row\">";

let strFooter=  "</div>"+
"</div>"+
"</div>";  

    //alert(lsData.length);
    for(var i=0;i<lsData.length;i++){
        
 var stylBgImg="";
 //alert("check img== "+lsData[i]["col4"]);
 if(lsData[i]["col4"]!==""){
    stylBgImg="<img class=\"img-responsive w-100\" src=\""+appPath+lsData[i]["col4"].trim()+"\" style=\"width:100%;height:auto;postion:absolute\" />";
     //stylBgImg="style=\"background:url("+appPath+lsData[i]["col4"].trim()+");background-repeat:no-repeat;background-size:100% auto;background-position:center;\"";
 }
 let bgAssign="";

  let strBtn="";
        if(lsData[i]["col7"].trim()!==""){
            let arrStrBtn=lsData[i]["col8"].split(',');
            let arrBtnUrl=lsData[i]["col7"].split(',');
            for(let b=0;b<arrStrBtn.length;b++){
                strBtn+= "<a href=\""+arrBtnUrl[b]+"\" class=\"btn-transparent btn-hover-dark btn-2 pdall-10px \">"+arrStrBtn[b]+"</a>";    
            }
            
        }

 //alert("check== "+lsData[i]["col12"]);
 if(lsData[i]["col12"]!==""){
    bgAssign=lsData[i]["col12"];
 }   
  
 if(stylBgImg!==""){
    strResp+=  
            "<div class=\"col-6 grid_image_cs\" style=\"position:relative\" align=\"center1\">"+
            "<div class=\"\">"+
            stylBgImg+
            "</div>"+
            
            "<div data-aos=\"fade-up\" data-aos-duration=\"2000\" class=\"container-middle "+bgAssign+" privacy_content vertical-center-noabs_absolute section_2\">"+
            
            "<h2 class=\"title\">"+lsData[i]["col1"]+"</h2>"+
            "<p>"+lsData[i]["col2"]+"</p>"+
            "<p>"+lsData[i]["col3"]+"</p>"+
            strBtn+
            "</div>"+
            
            "</div>";
                
                
               
               
                
            
      
 
 }
     



    }
    
    return strHeader+strResp+strFooter;
}
export default Image_Grid;