import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import defaultImg from '../assets/images/default_thumb_astro.png';
import './article_listing.css';

export function Article_Listing_Arrow(lsData){
  //alert(JSON.stringify(lsData));
  //return JSON.stringify(lsData);
  var appUrtPath=appGetUrlPath();
  var listData=sortResults(lsData,"id",false);

  let strBgImg="";
  let title="";
  if(lsData.length>0){
    strBgImg=appUrtPath+lsData[0]["bg_img"];
    title=lsData[0]["g_title"];
    if(strBgImg!==""){
      strBgImg="style=\"background-size:cover;background-image:url("+strBgImg+")\"";
    }
  }
  //let isWhite="txt-white";
  let isWhite="";

  /* custome Conditions for data */
  let customDesc="";
  if(title=="offerings"){
    //alert("i m in condition")
    customDesc="<p>"+"we cater entire lifecycle of data Journey right for Architecture, engineering to Put it to business "
  } 


    
  
      var strResp="";
      let hrefLink="";
      let rowCount=0;
    for(var i=0;i<listData.length;i++){
      if(listData[i]["col10"]===""){ 
       hrefLink="blog\\"+urlTitle+"?rf="+lsData[i]["e_id"];
       if(listData[i]["col7"]!==""){
        hrefLink=listData[i]["col7"];
       }
       hrefLink="javascript:void(0)";
  
      var imgPath="default-product.png"; 
      var urlTitle= lsData[i]["col1"];
      urlTitle=urlTitle.replace("?","_");
      urlTitle=urlTitle.replace(" ","-");
      if(listData[i]["col4"]!=""){
       imgPath=listData[i]["col4"];
        }
       
      

        strResp+="<div class=\"col-lg-4 col-md-4 col-sm-6 col-xs-6\">"+
        "<div class=\"border-box-12 product mb-5\">"+
        "<form>"+
              "<div class=\"thumb \">"+
              "<div class=\"box_arrow bg-blue_arrow"+(rowCount+1)+" mb-2\">"+
  "<span><b>"+listData[i]["col1"]+"</b></span>"+
"</div>"+
                "<a href=\""+hrefLink+"\" class=\"image\">"+
                    "<img src=\""+appUrtPath+imgPath+"\" alt=\"Product\" />"+
                  //   "<img class=\"hover-image\" src=\""+appUrtPath+imgPath+"\" alt=\"Product\" />"+
                "</a>"+
            "</div>"+
            "<div class=\"content1\">"+
           
                "<h5 class=\"title\"><a class=\"f_color_1\" href=\""+hrefLink+"\">"+listData[i]["col2"]+"</a></h5>"+
                "<span class=\"price1 heading article_thumb\">"+
                lsData[i]["col3"]+
             "</span>"+
            "</div>"+
        "</form>"+"</div>"+
    "</div>";
    rowCount++
      }else if(listData[i]["col10"]==="top_text"){
        title=listData[i]["col1"];
        customDesc=listData[i]["col2"];
      }
    }

    var strHeader="<div "+strBgImg+" class=\"shop-category-area1 pwwb-100px pt-70px\">"+
    "<div class=\"container\">"+
    "<div class=\"row\">"+
      "<div class=\"col-md-12\">"+
          "<div class=\"heading page__title-bar1\">"+
              "<center><h3 class=\"title "+isWhite+"\">"+title+"</h3></center>"+
          "</div>"+
      "</div>"+
      "<div class=\"col-md-12 mb-5\">"+
      "<div style=\"text-align:center;\" class=\"heading page__title-bar1 "+isWhite+" \">"+
          customDesc+
      "</div>"+
  "</div>"+
  "</div>"+
       
            "<div class=\"row\">";
     var strFooter="</div></div></div>";

    return strHeader+strResp+strFooter;


  return strResp; 
}

export function Article_Listing_2(lsData,cols="0",newPath=""){

  let _article_list_ads_134="<div class=\"col-md-12\"><div id=\"ezoic-pub-ad-placeholder-134\"></div></div>";
  let _article_list_ads_133="<div class=\"col-md-12\"><div id=\"ezoic-pub-ad-placeholder-133\"></div></div>";

//alert(JSON.stringify(lsData));
  //return JSON.stringify(lsData);
  var appUrtPath=appGetUrlPath();
  if(newPath!==""){
    appUrtPath=newPath;
  }
  //var listData=sortResults(lsData,"id",false);
  var listData=lsData;

  let strBgImg="";
  let title="";
  if(lsData.length>0){
    //alert(lsData[0]["bg_img"]);
    strBgImg=appUrtPath+lsData[0]["bg_img"];
    title=lsData[0]["g_title"];
    if(strBgImg!==""){
      strBgImg="style=\"background-size:cover;background-image:url("+strBgImg+")\"";
    }
  }
  //let isWhite="txt-white";
  let isWhite="";

  /* custome Conditions for data */
  let customDesc="";
  if(title=="offerings"){
    //alert("i m in condition")
    customDesc="<p>"+"we cater entire lifecycle of data Journey right for Architecture, engineering to Put it to business "
  } 

  var strResp="";
      let hrefLink="";
    for(var i=0;i<listData.length;i++){
      if(listData[i]["col10"]===""){
        hrefLink="blog\\"+urlTitle+"?rf="+lsData[i]["e_id"];
        if(newPath!==""){
          hrefLink=lsData[i]["e_id"];
        }
        
        if(listData[i]["col7"]!==""){
         hrefLink=listData[i]["col7"];
        }
   
       var imgPath=appUrtPath+"default-product.png"; 
       var urlTitle= lsData[i]["col1"];
       urlTitle=urlTitle.replace("?","_");
       urlTitle=urlTitle.replace(" ","-");
       if(listData[i]["col4"]!=""){
        imgPath=appUrtPath+listData[i]["col4"];
         }else{
          imgPath= defaultImg;
         }

        
         
         strResp+="<div data-aos=\"fade-up\" data-aos-duration=\"2000\" class=\"col-lg-4 col-md-4 col-sm-6 col-xs-6\">"+
         "<div class=\"border-box-2 product mb-5\">"+
         "<form>"+
               "<div class=\"thumb \">"+
                 "<a href=\""+hrefLink+"\" class=\"image\">"+
                     "<img src=\""+imgPath+"\" alt=\"Product\" />"+
                   //   "<img class=\"hover-image\" src=\""+appUrtPath+imgPath+"\" alt=\"Product\" />"+
                 "</a>"+
             "</div>"+
             "<div class=\"content\">"+
            
                 "<h5 class=\"title\"><a class=\"f_color_1\" href=\""+hrefLink+"\">"+lsData[i]["col1"]+"</a></h5>"+
                 "<span class=\"price1 heading article_thumb\">"+
                 lsData[i]["col2"]+
              "</span>"+
             "</div>"+
         "</form>"+"</div>"+
     "</div>";
     if(i!==0 && (i+1)%3===0){
      if((i+1)===3){
        strResp+=_article_list_ads_134;
      }
      if((i+1)===6){
      strResp+=_article_list_ads_133;
     }
    }
      }else if(listData[i]["col10"]==="top_text"){
        title=listData[i]["col1"];
        customDesc=listData[i]["col2"];
      } 
       
    }

    var strHeader="<div "+strBgImg+" class=\"shop-category-area pb-10 pt-10\">"+
    "<div class=\"container\">"+
    "<div class=\"row\">"+
      "<div class=\"col-md-12\">"+
          "<div class=\"heading page__title-bar\">"+
              "<h3 class=\"title "+isWhite+"\">"+title+"</h3>"+
          "</div>"+
      "</div>"+
      "<div class=\"col-md-12 mb-5\">"+
      "<div class=\"heading page__title-bar "+isWhite+" \">"+
          customDesc+
      "</div>"+
  "</div>"+
  "</div>"+
        "<div class=\"row\">"+
            "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
            "<div class=\"shop-bottom-area\">"+
            "<div class=\"row\">";
     var strFooter="</div></div></div></div></div></div>";
    if(cols!=="0"){
      //alert("i m in colds");
      strHeader="<div class=\"row container\"><div class=\"col-md-8\">"+strHeader;
      strFooter=strFooter+"</div><div class=\"col-md-4\" id=\"article_listing_child\"></div></div>";
    } 

    return strHeader+strResp+strFooter;
}

export function Article_Listing(lsData){
  //alert(JSON.stringify(lsData));
  //return JSON.stringify(lsData);
    var appUrtPath=appGetUrlPath();
var listData=sortResults(lsData,"id",false);
//let listData=lsData;
    var strHeader="<div class=\"shop-category-area pb-100px pt-70px\">"+
    "<div class=\"container\">"+
        "<div class=\"row\">"+
            "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
            "<div class=\"shop-bottom-area\">"+
            "<div class=\"row\">";
     var strFooter="</div></div></div></div></div></div>";

    var strResp="";
  for(var i=0;i<listData.length;i++){
     

    var imgPath="default-product.png"; 
    var urlTitle= lsData[i]["col1"];
    urlTitle=urlTitle.replace("?","_");
    urlTitle=urlTitle.replace(" ","-");
    if(listData[i]["col4"]!=""){
     imgPath=listData[i]["col4"];
      }
      strResp+="<div class=\"col-lg-3 col-md-4 col-sm-6 col-xs-6\">"+
      "<div class=\"border-box-2 product mb-5\">"+
      "<form>"+
            "<div class=\"thumb\">"+
              "<a href=\"blog\\"+urlTitle+"?rf="+lsData[i]["e_id"]+"\" class=\"image\">"+
                  "<img src=\""+appUrtPath+imgPath+"\" alt=\"Product\" />"+
                //   "<img class=\"hover-image\" src=\""+appUrtPath+imgPath+"\" alt=\"Product\" />"+
              "</a>"+
          "</div>"+
          "<div class=\"content\">"+
          "<span class=\"price1 heading article_thumb\">"+
          "<span style=\"float:left;\" class=\"title\">"+listData[i]["f_date"]+"</span>"+
          "<span style=\"float:right;\" class=\"title \">"+"live <i class=\"ion-android-favorite-outline\"></i>"+getRandomInt(100)+"</span>"+
       "</span>"+
              "<h5 class=\"title\"><a href=\"blog\\"+urlTitle+"?rf="+lsData[i]["e_id"]+"\">"+listData[i]["col1"]+"</a></h5>"+
          "</div>"+
      "</form>"+"</div>"+
  "</div>";
  }
  return strHeader+strResp+strFooter;

}
function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
export function sortResults(listjs,prop, asc) {
  listjs.sort(function(a, b) {
    
      if (asc) {
          return (parseInt(a[prop]) > parseInt(b[prop])) ? 1 : ((parseInt(a[prop]) < parseInt(b[prop])) ? -1 : 0);
      } else {
          return (parseInt(b[prop]) > parseInt(a[prop])) ? 1 : ((parseInt(b[prop]) < parseInt(a[prop])) ? -1 : 0);
      }
  });
  return listjs;
}
export default Article_Listing;