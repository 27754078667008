import $ from 'jquery';
import React from 'react';
import './site_map_component.css';
export function Site_Map_Component(lsData){
    //alert("site compoent=="+JSON.stringify(lsData));
    const listFltr = d => d.topid == 0;
    var topMenusList = lsData.filter(listFltr);
    //alert(topMenusList.length);
  return (<div><ul class="dd-list pt-10">
    {topMenusList.map((item,index)=>{
        
      let menuList1=lsData.filter(d=>d.topid==item.id);
      return (<li class="dd-item" data-id={item.id}>
      <a href={"../"+item.pageurlname}>{(index+1)+". "+item.pagename}</a>
      <ul class="dd-list12">
       {menuList1.map((item1,index1)=>{
        let menuList2=lsData.filter(d=>d.topid==item1.id);
return <li class="dd-item" data-id={item1.id}>
      <a href={"../"+item1.pageurlname}>{(index+1)+"."+(index1+1)+". "+item1.pagename}</a>
      <ul class="dd-list12">
        {menuList2.map((item2,index2)=>{
            return <li class="dd-item" data-id={item2.id}>
                <a href={"../"+item2.pageurlname}>{(index2+1)+". "+item2.pagename}</a>
                </li>
        })}
        </ul>
    </li>  
           
       })}
        </ul>
       
      </li>)
    })}
    </ul>
  </div>);
}
export default Site_Map_Component;
