import React from 'react';
export function Footer_Register(){
    return(
        <div class="border-box txt-white bg-strip1 privacy_policy_main_area ">
              <div class="container pb-20px pt-20px">
                  <div class="container-inner">
                      <div class="row">
                        <div class="col-md-6">
                         <h5 className='txt-white'>Ready to Get Started</h5>
                         lorem ipsum ts hello testing of register and working
                        </div>
                        <div class='col-md-6 text-right'>
                        <a href="../contact_us" className="add-cart btn btn-primary btn-hover-primary ml-4">Register</a>
                        </div>
                      </div>
                  </div>
              </div>
          </div>);
}
export default Footer_Register;