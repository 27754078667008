import $ from 'jquery';
import React from 'react';
import {CallMapMethod,AjaxCallMethod} from '../function_lib/common_lib';
import {getUserObjData} from '../function_lib/userToken';
import Front_Header from './front_header';
import Footer from './footer';
import AOS from 'aos';
import ImgUPI from '../assets/images/upi_qr.jpeg'
import './step_two.css';

let clsObject;
let cartItems;
let finalAmt=0;
let userObject;
let couponDiscount=0;


class Step_Two extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        cartItems=localStorage.getItem("_cart");
  
        var strList="";
          let finalAmt=0;
        if(cartItems!=null && cartItems!==""){
            cartItems=JSON.parse(cartItems);
        }
        userObject= getUserObjData();
        
        //alert(userObject[0].id);
    }

    checkShipping(){
        let chkShip=document.getElementById("diff_shipping").checked;
        //alert(chkShip);
        $("#s_name").val("");
        $("#s_country").val("");
        $("#s_state").val("");
        $("#s_city").val("");
        $("#s_pincode").val("");
        $("#s_address1").val("");
        $("#s_address2").val("");
        if(chkShip){
            $("#s_name").val($("#b_name").val());
            $("#s_country").val($("#b_country").val());
            $("#s_state").val($("#b_state").val());
            $("#s_city").val($("#b_city").val());
            $("#s_pincode").val($("#b_pincode").val());
            $("#s_address1").val($("#b_address1").val());
            $("#s_address2").val($("#b_address2").val());

            if($("#s_state").val()==null){
                this.getStateShipping();
            }
        }
    }

    okSuccInv(data,datares){
        if(data==="not_validated"){
            $("#getRegLogn").html(datares);
            return;
          }
      //alert("on steptow="+data+" = "+datares);
      if(data!=="" && data!=="0"){
        /* Logic to work on callback */
        window.location.href="https://themiddlemans.com/invoice_receipt?rf="+data;
        /*
        let payOpt=$("input[type='radio'][name='cmb_payment_methos']:checked").val()
        if(payOpt==="cod"){
            window.location.href="https://mbdhwellness.com/invoice_receipt?rf="+data;
        }else if(payOpt==="payNow"){
            window.location.href="https://mbdhwellness.com/invoice_receipt?rf="+data;
           // window.location.href="https://adlog.mbdhwellness.com/securePayNow?token="+data;
        }
        */
        
      }
    }

    getState(){
        var mapCat=[];
        mapCat.push({name:"curl_type",value:"getCountry"});
        mapCat.push({name:"res_func",value:"callBackSt"});
        mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
        mapCat.push({name:"p1",value:$("#b_country").val()});
        mapCat.push({name:"p2",value:"1"});
       // mapCat.push({name:"emp_id",value:userObject[0].id});
        mapCat["res_func"]="callBackSt";
        mapCat["curl_type"]="getCountry";
        CallMapMethod(clsObject,mapCat);
    }
    callBackSt(data,msg){
        if(data!=="" && data!=="0"){
            var listState=JSON.parse(data);
            var strState="<option value=\"0\">-Select-</option>";
            for(var i=0;i<listState.length;i++){
                strState+="<option value=\""+listState[i]["location_id"]+"\">"+listState[i]["name"]+"</option>"
            }
            $("#b_state").html(strState);
        }
    }

    getStateShipping(){
        
        var mapCat=[];
        mapCat.push({name:"curl_type",value:"getCountry"});
        mapCat.push({name:"res_func",value:"callBackStShip"});
        mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
        mapCat.push({name:"p1",value:$("#s_country").val()});
        mapCat.push({name:"p2",value:"1"});
       // mapCat.push({name:"emp_id",value:userObject[0].id});
        mapCat["res_func"]="callBackStShip";
        mapCat["curl_type"]="getCountry";
        CallMapMethod(clsObject,mapCat);
    }
    callBackStShip(data,msg){
        if(data!=="" && data!=="0"){
            var listState=JSON.parse(data);
            var strState="<option value=\"0\">-Select-</option>";
            for(var i=0;i<listState.length;i++){
                strState+="<option value=\""+listState[i]["location_id"]+"\">"+listState[i]["name"]+"</option>"
            }
            $("#s_state").html(strState);
            if(document.getElementById("diff_shipping").checked){
                this.checkShipping();
            }
            
        }
    }


    getCountry(){
       
        var mapCat=[];
        mapCat.push({name:"curl_type",value:"getCountry"});
        mapCat.push({name:"res_func",value:"callBackCntry"});
        mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
        mapCat.push({name:"p1",value:"0"});
        mapCat.push({name:"p2",value:"0"});
       // mapCat.push({name:"emp_id",value:userObject[0].id});
        mapCat["res_func"]="callBackCntry";
        mapCat["curl_type"]="getCountry";
        CallMapMethod(clsObject,mapCat);
    }
    callBackCntry(data,msg){
        if(data!=="" && data!=="0"){
            var listCountry=JSON.parse(data);
            var strCountry="<option value=\"0\">-Select-</option>";
            for(var i=0;i<listCountry.length;i++){
                strCountry+="<option value=\""+listCountry[i]["location_id"]+"\">"+listCountry[i]["name"]+"</option>"
            }
            $("#b_country").html(strCountry);
            $("#s_country").html(strCountry);
        }
       // alert(data);
    }
    checkShippingCost(){
         /* Set Cart Item Cost */
       let totalCost=0;
       for(var ci=0;ci<cartItems.length;ci++){
           totalCost+= parseFloat(cartItems[ci]["amount"]);
       }
       //alert(totalCost);
       if(parseFloat(totalCost)<500){
        $(".shipping_cost").html("₹"+"43.00");
        //alert(totalCost);
        $(".tot_withship").html("₹"+(parseFloat(totalCost)+43).toFixed(2));
        if($("#b_state").val()!=="744"){
            $(".shipping_cost").html("₹"+"70.00");
            $(".tot_withship").html("₹"+(parseFloat(totalCost)+70).toFixed(2));
            
        }
       }else{
        $(".shipping_cost").html("Free Shipping");
       }
       
    }
    checkCouponCode(){
        let mapCat=[];
        mapCat.push({name:"curl_type",value:"checkCouponCode"});
        mapCat.push({name:"res_func",value:"callBackProCoupon"});
        mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
        mapCat.push({name:"p1",value:$("#coupon_disc").val()});
        mapCat.push({name:"p2",value:finalAmt});
        mapCat["res_func"]="callBackProCoupon";
        mapCat["curl_type"]="checkCouponCode";
       CallMapMethod(clsObject,mapCat);
    }
    callBackProCoupon(data,msg){
        //alert(data);
        if(data!=="0"){
            finalAmt=0;
            let couponData=JSON.parse(data);
            //alert(couponData["amount"]);
            $("#coupon_break").val(data);
            couponDiscount=parseFloat(couponData["amount"]);
            if(cartItems!=null && cartItems!==""){
                // cartItems.push({"item_name":couponData["item_name"],"item_code":couponData["item_code"],
                // "item_rate":couponData["amount"],"item_quanity":"1","amount":couponData["amount"],"company_name":"0","item_rf_id":"0","img_c":""});
                    }
                   //alert(JSON.stringify(cartItems));
                   //localStorage.setItem("_cart",JSON.stringify(cartItems));
                   //this.setState(state=>({setCartItems:cartItems,isUpdate:true}));
                   this.forceUpdateHandler();
                //    window.location.reload();
            }
           
    } 

    componentDidMount(){
        clsObject=this;
        this.getCountry();
       // alert("check data");
       AOS.init();
       AOS.init({
         once: true
       });
       const script = document.createElement("script");
       script.src = "/main.js";
       script.async = true;
       //script.onload = () => clsObject.scriptLoaded();
     
       document.body.appendChild(script);

       AjaxCallMethod(clsObject,"okSuccCNOT");
       //Init 
       $("#loc_token").val(userObject[0].id);
       var cartItems= localStorage.getItem("_cart");
       let pItemList=JSON.stringify(cartItems);
       //let resPList ="{\"tt\":["+pItemList+"]}";
       //alert(JSON.parse(cartItems).length);
       $("#item_list_token").val(cartItems);
    }
    render(){
        return(<div>
             <Front_Header/>
             {/* <!-- checkout area start --> */}
            <div className="checkout-area pt-100px pb-100px">
            <div className="container">
                <div className="row">
                <div className="col-lg-5 mt-md-30px mt-lm-30px ">
                        <div className="border-box sticky your-order-area">
                            <h3>Your order</h3>
                            <div className="your-order-wrap gray-bg-4">
                                <div className="your-order-product-info">
                                    <div className="your-order-top">
                                        <ul>
                                            <li>Product</li>
                                            <li>Total</li>
                                        </ul>
                                    </div>
                                    <div className="your-order-middle">
                                        <ul>
                                        {(cartItems) ? cartItems.map((item,index)=>{
            //alert(cartItems.length);
            if(item.item_name==="coupon discount"){
                couponDiscount=item.item_rate;
                //finalAmt-=parseFloat(item.item_rate);
            }else{
                finalAmt+=parseFloat(item.amount);
                //alert(finalAmt);
            }
            
            window.cAmount=finalAmt;
           return <li><span className="order-middle-left">{item.item_name} X {item.item_quantity}</span> <span className="order-price">₹{item.amount}</span></li>
                                           }):''
                                    }
                                        </ul>
                                    </div>
                                    {couponDiscount!==0? <div className="your-order-bottom">
                                        <ul>
                                            <li className="your-order-shipping">Coupon Discount</li>
                                            <li className='shipping_cost12'>{-couponDiscount}</li>
                                        </ul>
                                    </div>:''}
                                    <div className="your-order-bottom">
                                        <ul>
                                            <li className="your-order-shipping">Shipping</li>
                                            <li className='shipping_cost'></li>
                                        </ul>
                                    </div>
                                    {(userObject!=="" && couponDiscount===0 && cartItems!=null && cartItems!==""?<div className="your-order-total coupon_apply  row">
                                        <div className='col-6'><input type='text' id="coupon_disc" name="coupon_disc" placeholder='coupon code' /></div>
                                        <div className='col-6'><button type='button' onClick={()=>this.checkCouponCode()} className='btn text-center btn-primary btn-hover-primary mr-4'>Apply</button></div>
                                    </div>:'')}
                                    <div className="your-order-total">
                                        <ul>
                                            <li className="order-total">Total</li>
                                            <li className='tot_withship'>₹{finalAmt-parseInt(couponDiscount)}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="payment-method">
                                    <div className="payment-accordion element-mrg">
                                        <div id="faq" className="panel-group">
                                            <div className="panel panel-default single-my-account m-0">
                                                <div className="panel-heading my-account-title">
                                                    <h4 className="panel-title"><a data-bs-toggle="collapse" href="#my-account-1" className="collapsed" aria-expanded="true">Shipping Details</a>
                                                    </h4>
                                                </div>
                                                <div id="my-account-1" className="panel-collapse collapse show" data-bs-parent="#faq">
            
                                                    <div className="panel-body">
                                                        <p>Free Shipping on All Orders Over ₹ 500!</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="panel panel-default single-my-account m-0">
                                                <div className="panel-heading my-account-title">
                                                    <h4 className="panel-title"><a data-bs-toggle="collapse" href="#my-account-2" aria-expanded="false" className="collapsed">Check payments</a></h4>
                                                </div>
                                                <div id="my-account-2" className="panel-collapse collapse" data-bs-parent="#faq">
            
                                                    <div className="panel-body">
                                                        <p>Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <div className="panel panel-default single-my-account m-0">
                                                <div className="panel-heading my-account-title">
                                                    <h4 className="panel-title"><a data-bs-toggle="collapse" href="#my-account-3">Cash on delivery</a></h4>
                                                </div>
                                                <div id="my-account-3" className="panel-collapse collapse" data-bs-parent="#faq">
            
                                                    <div className="panel-body">
                                                        <p>Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-box col-lg-7">
                        <div className="billing-info-wrap">
                        <form id="frm_billing_add" name="frm_billing_add">
                        <input type="hidden" name="curl_type" id="curl_type" value="addToInvoice" />  
                <input type="hidden" name="m_type" id="m_type" value="addToInvoice" />
                <input type="hidden" name="loc_token" id="loc_token" />
                <input type="hidden" name="gb_token" id="gb_token" value="5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg" />
                <input type="hidden" name="item_list_token" id="item_list_token" />
                <input type="hidden" name="final_amount" id="final_amount" value={finalAmt-parseInt(couponDiscount)} />
                <input type="hidden" name="coupon_break" id="coupon_break"/>
                <input type="hidden" name="res_func" id="res_func" value="okSuccInv" />
                
                           <div id="billing_add">
                            <h3>Billing Address</h3>
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="billing-info mb-4">
                                        <label>Full Name</label>
                                        <input className='required' id="b_name" name="b_name" type="text" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="billing-select mb-4">
                                        <label>Country</label>
                                        <select className="select_target required" resp_dest="getState" id="b_country" name="b_country">
                                            <option>Select a country</option>
                                          
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="billing-select mb-4">
                                        <label>State</label>
                                        <select className='required' onChange={()=>this.checkShippingCost()} id="b_state" name="b_state">
                                           
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-g col-md-6">
                                    <div className="billing-info mb-4">
                                        <label>Town / City</label>
                                        <input className='required' id="b_city" name="b_city" type="text" />
                                    </div>
                                </div>
                               
                                <div className="col-lg-6 col-md-6">
                                    <div className="billing-info mb-4">
                                        <label>Postcode / ZIP</label>
                                        <input className='required' id="b_pincode" name="b_pincode" type="text" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="billing-info mb-4">
                                        <label>Address</label>
                                        <input className="billing-address required" id="b_address1" name="b_address1" placeholder="House number and street name" type="text" />
                                        <input placeholder="Apartment, suite, unit etc." id="b_address2" name="b_address2" type="text" />
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <hr></hr>
                            <p className="bottomtxt"> <input type="checkbox" onClick={()=>this.checkShipping()} id="diff_shipping" name="diff_shipping" value="Different Shipping" />
<label for="diff_shipping">Same As Shipping Address</label></p>
                            </div>
                          
                            <div id="shiiping_add">
                               
                            <h3>Shipping Address</h3>
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="billing-info mb-4">
                                        <label>Full Name</label>
                                        <input className='required' id="s_name" name="s_name" type="text" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="billing-select mb-4">
                                        <label>Country</label>
                                        <select className="select_target required" resp_dest="getStateShipping" id="s_country" name="s_country">
                                            <option>Select a country</option>
                                          
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="billing-select mb-4">
                                        <label>State</label>
                                        <select className='required' id="s_state" name="s_state">
                                           
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-g col-md-6">
                                    <div className="billing-info mb-4">
                                        <label>Town / City</label>
                                        <input className='required' id="s_city" name="s_city" type="text" />
                                    </div>
                                </div>
                               
                                <div className="col-lg-6 col-md-6">
                                    <div className="billing-info mb-4">
                                        <label>Postcode / ZIP</label>
                                        <input className='required' id="s_pincode" name="s_pincode" type="text" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="billing-info mb-4">
                                        <label>Address</label>
                                        <input className="billing-address required" id="s_address1" name="s_address1" placeholder="House number and street name" type="text" />
                                        <input placeholder="Apartment, suite, unit etc." id="s_address2" name="s_address2" type="text" />
                                    </div>
                                </div>
                            </div>
                           
                            </div>
                            <br></br>
                            <hr></hr>
                            <div id="payment_opt_add">
                            <h3>Payment Methods</h3>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6'>
                                    <img className='image-resp' src={ImgUPI}  />
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    
                                        <h3>NEFT /IMPS </h3>
                                        <p>Bank Name :<b>ICICI Bank</b></p>
                                        <p>A/c Name : <b>AND IT SOLUTION</b></p>
                                        <p>A/c Number : <b>254105001193</b></p>
                                        <p>IFSC :<b>ICICI0002541</b></p>
                                    
                                </div>
                                <div className='col-lg-12 col-md-12'>
                                <div className="billing-info mb-4">
                                        <label>Transaction ID</label>
                                        <input className='required' id="transaction_id" name="transaction_id" type="text" />
                                    </div>  
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-lg-12 col-md-12">
                                <input type="radio" id="html" name="cmb_payment_methos" value="payNow" />
<label for="html">Pay Online/Internet Banking/PhonePay/GPay/Debit Card</label>
                                    
                                </div>
                                <div className="col-lg-12 col-md-12">
                               
                                <input type="radio" id="css" name="cmb_payment_methos" value="cod"/>
<label for="css">COD(Cash on Delivery)</label>
                                </div>
                                </div> */}
                            </div>
                            {/* <div className="additional-info-wrap">
                                <h4>Additional information</h4>
                                <div className="additional-info">
                                    <label>Order notes</label>
                                    <textarea placeholder="Notes about your order, e.g. special notes for delivery. " name="message"></textarea>
                                </div>
                            </div> */}
                            <div id="getRegLogn" style={{color:"red"}}></div>
                            <div className="Place-order mt-25">
                                        <button className="btn btn-primary btn-outline-dark current-btn _btnSetColor" type="button"><span>Place Order</span></button>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
             {/* <!-- checkout area end --> */}
           <Footer/>
           </div>
            )
    }
}
export default Step_Two;