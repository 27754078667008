import $ from 'jquery';
import React from 'react';
//import {appGetUrlPath} from '../function_lib/common_cms';
import {appGetUrlPath} from '../function_lib/common_lib';
import defaultImg from '../assets/images/default_thumb_astro.png';
import './comp_recent_blog.css';


let appPathCMS="";
let appPathLib="";
let isRecent=false;
class Comp_Recent_Blog extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        appPathCMS=appGetUrlPath();
        appPathLib=appGetUrlPath();
        isRecent=true;
    }
    componentWillReceiveProps(props){
        this.props=props;
        
        if(this.props.blogData!==""){
           // alert(this.props.blogData.length);
            //alert("recent child=="+JSON.stringify(this.props.blogData));
            isRecent=true;
        }
        
        //alert("child props recent"+JSON.stringify(this.props.blogData));
        //alert(this.props.blogData["col4"]);
        this.forceUpdateHandler();
        //$("#blog_landing").html(this.props.blogData["col2"]);
    }
    componentDidMount(){
       
    }
    render(){
        return(<div className="sticky12 left-sidebar pt-10 shop-sidebar-wrap">
    
        <div className="sidebar-widget recent-blog-wid">
            <h3 className="sidebar-title">Recent Post</h3>
            <div className="recent-post-widget">
                {(isRecent)? this.props.blogData.map((item,index)=>{
                    //alert("path==>"+item.col4.trim()+"== ");
                   var urlBlog="/blog/"+item.col1+"?rf="+item.e_id;
                   if(typeof item.pageurlname !=="undefined" && item.pageurlname!==""){
                    urlBlog="../"+item.pageurlname;
                   }
                   return <div className="recent-single-post d-flex">
                   <div className="thumb-side">
                       <a href={urlBlog}><img src={item.col4.trim()!==""?appPathLib+item.col4:defaultImg} alt="" /></a>
                   </div>
                   <div className="media-side">
                       <a href={urlBlog}><h6>{item.col1}</h6></a>
                       
                      
                   </div>
               </div>
                }):''}
           
            </div>
        </div>
      </div>)
    }
}
export default Comp_Recent_Blog;