import React from 'react';
import './home_banner.css';
import { appGetUrlPath } from '../function_lib/common_cms';


export function Home_Banner(lsData,cols="0",needAds=""){

    let _homeBanner_Ads_136="<div id=\"ezoic-pub-ad-placeholder-136\"> </div>";
let _homeBanner_Ads_135="<div id=\"ezoic-pub-ad-placeholder-135\"> </div>";

    var appPath=appGetUrlPath();
    var strResp="";
    var strHeader="<div data-aos=\"zoom-in-down\" class=\"hero_home section\">"+
    "<div class=\"hero-slider swiper-container slider-nav-style-1 slider-dot-style-1 dot-color-white\">"+
         "<div class=\"swiper-wrapper\">";

    var strFooter="</div><div class=\"swiper-pagination swiper-pagination-white\"></div>"+
            "<div class=\"swiper-buttons\">"+
        "<div class=\"swiper-button-next\"></div>"+
        "<div class=\"swiper-button-prev\"></div>"+
    "</div>"+
"</div>"+
"</div>";

if(cols!=="0"){
    strHeader="<div class=\"row container\"><div class=\"col-md-8\">"+strHeader;
    strFooter=strFooter+"</div><div id=\"home_banner_child\" class=\"col-md-4\"></div></div>";  
} 

let strBtn;
    for(var i=0;i<lsData.length;i++){
        strBtn="";
        if(lsData[i]["col7"].trim()!==""){
            strBtn= "<a href=\""+lsData[i]["col7"]+"\" class=\"btn btn-lg btn-primary btn-hover-dark mt-5 pt-100\">"+lsData[i]["col8"]+"</a>";
        }
       strResp+="<div class=\"hero-slide-item slider-height-2 swiper-slide d-flex\">"+
       "<div class=\"hero-bg-image\">"+
           "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\""+lsData[i]["col4"]+"\"/>"+
       "</div>"+
       "<div class=\"container\">"+
           "<div class=\"row\">"+
               "<div class=\"col-md-12 align-self-center\">"+
                   "<div class=\"hero-slide-content hero-slide-content-2 slider-animated-1 text-center\">"+
                       "<span class=\"category\">"+lsData[i]["col1"]+"</span>"+
                       "<p class=\"w-100\">"+lsData[i]["col2"]+"</p>"+
                        strBtn+
                   "</div>"+
               "</div>"+
           "</div>"+
       "</div>"+
   "</div>";
    }
    if(needAds!==""){
      strFooter=strFooter+_homeBanner_Ads_136+_homeBanner_Ads_135;
    }
    return strHeader+strResp+strFooter;
}
export default Home_Banner;