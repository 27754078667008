import $ from 'jquery';
import React from 'react';
export function Comp_Subscribe_Email(){
    return (<div className="get-quote-form pb-10 pt-10">
    <form  method="post" name="sub">
    <p><input type="text" placeholder="Enter Your E-mail address" className="w_def_text from-control" title="Enter Your Email Address" name="semail" required="true" /> </p>
     
        <p ><input className="add-cart btn btn-primary btn-hover-primary float-right" type="submit" value="Submit" name="subs"/></p>
    </form>
    
</div>)
}
export default Comp_Subscribe_Email;